import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from "react-toastify";
import { deleteAdminAccount, deleteUserAccount, listSubAdminOrUser, resetUserAccount, suspendUserAccount, userDetailById } from '../Redux/Actions/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const ConfirmModal = ({ isModalOpen, handleConfirmClose, data, selectedType }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleUserDelete = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(deleteUserAccount(value))
            if (res?.payload?.status == 200) {
                handleConfirmClose()
                toast.success(res?.payload?.message);
                navigate("/users?page=1")
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    const handleDeleteAdmin = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(deleteAdminAccount(value))
            if (res?.payload?.status == 200) {
                handleConfirmClose()
                toast.success(res?.payload?.message);
                dispatch(listSubAdminOrUser());
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }



    const handleUserSuspend = async () => {
        try {
            const value = {
                id: data,
                status: 1
            }
            const res = await dispatch(suspendUserAccount(value))
            if (res?.payload?.status == 200) {

                handleConfirmClose()
                toast.success(res?.payload?.message);
                dispatch(userDetailById(data))
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }





    const handleUserActive = async () => {
        try {
            const value = {
                id: data,
                status: 0
            }
            const res = await dispatch(suspendUserAccount(value))
            if (res?.payload?.status == 200) {
                handleConfirmClose()
                toast.success(res?.payload?.message);
                dispatch(userDetailById(data))
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }





    const handleUserReset = async () => {
        try {
            const value = {
                id: data
            }
            const res = await dispatch(resetUserAccount(value))
            if (res?.payload?.status == 200) {

                handleConfirmClose()
                toast.success(res?.payload?.message);
                dispatch(userDetailById(data))
            } else {
                toast.error(res?.payload?.message)
            }
        } catch (error) {
            console.log("error", error)
        }
    }



    return (
        <Modal
            show={isModalOpen}
            onHide={handleConfirmClose}
            className="comn-modal-set"
        >
            {selectedType === "userDelete" &&
                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Account Delete

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to delete?
                        </p>
                    </Modal.Body>
                </>

            }

            {selectedType === "adminDelete" &&
                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title border-none ">
                            Delete Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text delt-modal-txt">
                            {" "}
                            Are you sure you want to delete?
                        </p>
                    </Modal.Body>
                </>

            }


            {selectedType === "userSuspend" &&
                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Suspend Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to suspend?
                        </p>
                    </Modal.Body>
                </>

            }


            {selectedType === "userActive" &&
                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Active Account

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to active this account?
                        </p>
                    </Modal.Body>
                </>

            }

            {selectedType === "userReset" &&
                <>
                    <Modal.Header className="border-none" closeButton>
                        <Modal.Title className="text-center modal-title ">
                            Reset Password

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-center suspend-text">
                            {" "}
                            Are you sure you want to reset password of this account?
                        </p>
                    </Modal.Body>
                </>

            }




            <Modal.Footer className="border-none justify-content-center modal-footer">

                {selectedType === "userDelete" &&
                    <Button
                        onClick={() => handleUserDelete()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


                {selectedType === "userSuspend" &&
                    <Button
                        onClick={() => handleUserSuspend()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


                {selectedType === "userActive" &&
                    <Button
                        onClick={() => handleUserActive()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }


                {selectedType === "userReset" &&
                    <Button
                        onClick={() => handleUserReset()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }



                {selectedType === "adminDelete" &&
                    <Button
                        onClick={() => handleDeleteAdmin()}
                        variant="secondary"
                        className="modal-close-btn pop-cancel-btn "
                    >
                        Yes
                    </Button>
                }










                <Button
                    variant="primary"
                    onClick={handleConfirmClose}
                    className="modal-f-btn pop-cancel-btn"
                >
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmModal
