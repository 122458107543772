import { createSlice } from "@reduxjs/toolkit";
import { getTransactionData } from "../Actions/transactionData";

const initialState = {
  loading: false,
  transactionDataList: [],
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionData.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.transactionDataList = payload.data;
        } else if (!payload.success) {
        }
      })
  },
});

export default transactionSlice.reducer;
