import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getListOfSales, getSalesData } from "../Redux/Actions/salesData";
import NoData from "../components/NoData";
import { capitalize, formatDateToDMY } from "../Utils/commonFiles";
import Pagination from "../components/Pagination";
import { setCurrentPage } from "../Redux/Reducers/authSlice";

export default function Sales() {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const Image_url = process.env.REACT_APP_IMG_URL + "/uploads/";
  const salesData = useSelector((e) => e.salesAuth.salesData);
  const currentPage = useSelector((state) => state.userAuth.currentPage);

  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  

  // useEffect(() => {
  //   fetchSalesData(searchTerm, 1);
  // }, [searchTerm]);

  useEffect(() => {
    fetchSalesData(searchTerm, currentPage);
  }, [dispatch, searchTerm, currentPage]);


  const fetchSalesData = (search, page = 1, limit = 10) => {
    dispatch(startstopLoading(true));

    dispatch(getListOfSales({ searchTerm: search, page, limit })).then((res) => {
      if (res?.payload?.success !== undefined) {
        dispatch(startstopLoading(false));
      }
    });
  };


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchSalesData(value);
    }, 1000);
    setDebounceTimeout(timeout);
  };
  const handlePageClick = (selected) => {
    fetchSalesData(searchTerm, selected.selected + 1, 10);
    navigate(`/sales?page=${selected.selected + 1}`)
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const page = query.get('page') || 1;
    dispatch(setCurrentPage(parseInt(page, 10)))
  }, [location.search]);


  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span>Sales</span>
                <h4>All Sales </h4>
              </div>
              <div className="title-pro-right">
                <div className="">
                  <Link to="#" className="waves-effect">
                    <i className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_2242_20999)">
                          <path d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z" fill="#C0C0C0" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2242_20999">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mb-4 search-bar">
          <div className="">
            <div className="">
              <div>
                <input
                  type="search"
                  placeholder="Search"
                  className="input-common-properties"
                  onChange={handleSearchChange}
                  value={searchTerm}
                />
              </div>
            </div>
            {/* <div>
              <select className="input-common-properties bg-white ">
                <option className="input-common-properties ">
                  Sold Status
                </option>
                <option className="input-common-properties ">Active</option>
                <option className="input-common-properties ">
                  Sold Complete
                </option>
              </select>
            </div> */}

            <div>
              <select className="input-common-properties bg-white">
                <option className="input-common-properties ">Category</option>
                <option className="input-common-properties ">Clubs</option>
                <option className="input-common-properties ">
                  Sold Complete
                </option>
              </select>
            </div>

            <div>
              <select className="input-common-properties bg-white">
                <option className="input-common-properties ">
                  Sub Category
                </option>
                <option className="input-common-properties ">Drivers</option>
                <option className="input-common-properties ">
                  Sold Complete
                </option>
              </select>
            </div>
          </div>
          <div className="">
            <div>
              <button type="submit" className="export-btn">
                Export
              </button>
            </div>
          </div>
        </div>

        <Row>
          <Col lg={12}>
            <div class="card min-vh-80">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                  <h4 class="title_text">Sales ({salesData?.totalPayments ?? "0"})</h4>
                </div>

                <div class="table-responsive">
                  <table
                    id=""
                    class="table dt-responsive commom-tables nowrap w-100"
                  >
                    <thead>
                      <tr>
                        <th className="">S.No</th>
                        <th className="">Sold Date</th>
                        <th className="">Image</th>
                        <th className="">Item Name</th>
                        <th className="">Category </th>
                        <th className="">Sub Category</th>
                        <th className="">List Price</th>
                        <th className="">Offers</th>
                        <th className="">Status </th>
                        <th className="">Sale Price </th>
                        <th className="">Seller</th>
                        <th className="">Buyer</th>
                      </tr>
                    </thead>
                    <tbody class="td_color">
                      {salesData?.payments?.length > 0 ? salesData?.payments?.map((item, i) => (
                        <tr key={i}>
                          <td>{(salesData?.currentPage - 1) * 10 + i + 1}</td>
                          <td>{formatDateToDMY(item?.createdAt)}</td>

                          <td>
                            {item?.item_id?.item_Image?.length > 0 && (
                              <img
                                src={
                                  item?.item_id?.item_Image[0]?.startsWith("uploads/")
                                    ? `${process.env.REACT_APP_IMG_URL}/${item.item_id.item_Image[0]}`
                                    : `${Image_url}${item.item_id.item_Image[0]}`
                                }
                                alt=""
                                className="list-image"
                              />
                            )}

                          </td>
                          <td>
                            <Link to={`/salesDetail/${item?.item_id?._id}/${item?.paymentIntentId}/${item?.room_id}/${(salesData?.currentPage - 1) * 10 + i + 1}`} >{item?.item_id?.item_Title ?? "-"}</Link>
                          </td>
                          <td>
                            <Link to={`/salesDetail/${item?.item_id?._id}/${item?.paymentIntentId}/${item?.room_id}/${(salesData?.currentPage - 1) * 10 + i + 1}`} >{item?.item_id?.category?.category_name ?? "-"}</Link>
                          </td>
                          <td>
                            <Link to={`/salesDetail/${item?.item_id?._id}/${item?.paymentIntentId}/${item?.room_id}/${(salesData?.currentPage - 1) * 10 + i + 1}`} >{item?.item_id?.sub_Category?.sub_category_name ?? "-"}</Link>
                          </td>

                          {/* <td>{item?.item_id?.totalPrice != null ? `£${item?.item_id?.totalPrice?.toFixed(2)}` : "-"}</td> */}
                          <td>£ {item?.item_id?.price !== undefined ? item?.item_id?.price?.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>


                          <td>{item?.totalOffers || "0"}</td>
                          <td>{capitalize(item?.status)}</td>
                          {/* <td>{item?.amount != null ? `£${item?.amount?.toFixed(2)}` : "-"}</td> */}
                          <td>£ {item?.normal_price !== undefined ? item?.normal_price?.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>

                          <td>
                            <Link to={`/user-overview/${item?.item_id?.user_id?._id}/${item?.item_id?.user_id?.user_name}`}>
                              {item?.item_id?.user_id?.user_name
                                ? `@${item?.item_id?.user_id?.user_name}`
                                : "-"}
                            </Link>
                          </td>
                          <td> <Link to={`/user-overview/${item?.userId?._id}/${item?.userId?.user_name}`} >
                            {item?.userId?.user_name
                              ? `@${item?.userId?.user_name}`
                              : "-"}</Link></td>

                        </tr>
                      )) : <NoData />}

                    </tbody>
                  </table>

                  {salesData?.totalPages > 1 && (
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageCounttotal={
                        Math.ceil(salesData?.totalPages) || 1
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
