import { createSlice } from "@reduxjs/toolkit";
import { getFeedBackData } from "../Actions/feedBackData";

const initialState = {
  loading: false,
  feedbackDataList: [],
};

const FeedBackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFeedBackData.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.feedbackDataList = payload.data;
        } else if (!payload.success) {
        }
      })
  },
});

export default FeedBackSlice.reducer;
