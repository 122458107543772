import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Dashboard from "../pages/Dashboard";
import Listings from "../pages/Listings";
import ListingDetails from "../pages/ListingDetails";
import Users from "../pages/Users";
import UserOverview from "../pages/UserOverview";
import UserListings from "../pages/UserListings";
import UserSold from "../pages/UserSold";
import UserPurchases from "../pages/UserPurchases";
import UserReviews from "../pages/UserReviews";
import UserTransactions from "../pages/UserTransactions";
import MailingList from "../pages/MailingList";
import Sales from "../pages/Sales";
import SalesListing from "../pages/SalesListing";
import Transactions from "../pages/Transactions";
import Feedbacks from "../pages/Feedbacks";
import PushNotification from "../pages/PushNotification";
import CreateNotification from "../pages/CreateNotification";
import AdminSettings from "../pages/AdminSettings";
import PasswordSettings from "../pages/PasswordSettings";
import NotFound from "../pages/NotFound";
import SignIn from "../pages/SignIn";
import Otp from "../pages/Otp";
import ForgetPassword from "../pages/ForgetPassword";
import ChangePassword from "../pages/ChangePassword";
import CreateAdmin from "../pages/CreateAdmin";
import BuyerProtection from "../pages/BuyerProtection";
import TermsConditon from "../pages/TermsConditon";
import CookiesPolicy from "../pages/CookiesPolicy";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import AboutUsWedged from "../pages/AboutUsWedged";

const RoutePages = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<SignIn />} />
        <Route path="/otp/:id" element={<Otp />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/change-password/:id" element={<ChangePassword />} />
        <Route path="/purchase-protection" element={<BuyerProtection />} />
        <Route path="/termsandcondition" element={<TermsConditon />} />
        <Route path="/cookiespolicy" element={<CookiesPolicy />} />
        <Route path="/privatepolicy" element={<PrivacyPolicy />} />
        <Route path="/about-us-wedged" element={<AboutUsWedged />}></Route>

        {/* Protected Routes */}
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/listings" element={<Listings />} />
          <Route path="/listing-details" element={<ListingDetails />} />
          <Route path="/users" element={<Users />} />
          <Route path="/user-overview/:id/:name" element={<UserOverview />} />
          <Route path="/user-list/:id/:name" element={<UserListings />} />
          <Route path="/user-sold/:id/:name" element={<UserSold />} />
          <Route path="/user-purchases/:id/:name" element={<UserPurchases />} />
          <Route path="/user-reviews/:id/:name" element={<UserReviews />} />
          <Route path="/usertransaction/:id/:name" element={<UserTransactions />} />
          <Route path="/mailing-list" element={<MailingList />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/salesDetail/:id/:paymentId/:roomId/:number" element={<SalesListing />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/feedbacks" element={<Feedbacks />} />
          <Route path="/push-notification" element={<PushNotification />} />
          <Route path="/create-notification" element={<CreateNotification />} />
          <Route path="/create-admin" element={<CreateAdmin />} />
          <Route path="/edit-admin/:id" element={<CreateAdmin />} />
          <Route path="/admin-settings" element={<AdminSettings />} />
          <Route path="/password-settings" element={<PasswordSettings />} />
    
        </Route>

        {/* Catch-all route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutePages;
