import { Link, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { useEffect, useState } from "react";
import { getPushNotificationData } from "../Redux/Actions/notificationData";
import NoData from "../components/NoData";
import Pagination from "../components/Pagination";

export default function PushNotification() {

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [searchTerm, setSearchTerm] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const Image_url = process.env.REACT_APP_IMG_URL + "/uploads/";

  const notificationList = useSelector((e) => e.notificationAuth.notifiactionDataList);

  useEffect(() => {
    fetchPushNotificationData(1, 10, searchTerm);
  }, [dispatch]);

  const fetchPushNotificationData = (page, limit, search) => {
    dispatch(startstopLoading(true));
    dispatch(getPushNotificationData({ page, limit, search })).then((res) => {
      if (res?.payload?.success === true || res?.payload?.success === false) {
        dispatch(startstopLoading(false));
      }
    });
  };

  const handlePageClick = (selected) => {
    fetchPushNotificationData(selected.selected + 1, 10, searchTerm);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchPushNotificationData(1, 10, value);
    }, 1000);
    setDebounceTimeout(timeout);
  };

  console.log(notificationList, "notificationList")

  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span> Notification</span>
                <h4>All Push Notification </h4>
              </div>
              <div className="title-pro-right">
                {/* <ul>
                  <li className="actve">
                    <Link to="/create-notification">Create New</Link>
                  </li>
                </ul> */}

                <div className="">
                  <Link to="#" className="waves-effect">
                    <i className="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_2242_20999)">
    <path d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z" fill="#C0C0C0"/>
  </g>
  <defs>
    <clipPath id="clip0_2242_20999">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mb-4 search-bar">
          <div className="">
            <div className="">
              <div>
                <input
                  type="search"
                  placeholder="Search"
                  className="input-common-properties"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div>
              <select className="input-common-properties bg-white ">
                <option className="input-common-properties ">
                  Device Type
                </option>
                <option className="input-common-properties ">IOS</option>
                <option className="input-common-properties ">
                  Android
                </option>
              </select>
            </div>



          </div>
          {/* <div className="">
            <div>
              <button type="submit" className="export-btn">
                Export
              </button>
            </div>
          </div> */}
        </div>

        <Row>
          <Col lg={12}>
            <div class="card min-vh-80">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                  <h4 class="title_text">Listing </h4>
                </div>

                <div class="table-responsive">
                  <table
                    id=""
                    class="table dt-responsive nowrap "
                  >
                    <thead>
                      <tr>
                        <th className="id-field">S.No</th>
                        <th className="id-field">Username</th>
                        <th className="id-field">Feature Announcements</th>
                        <th className="id-field">Subscribe Newsletter</th>
                      </tr>
                    </thead>
                    <tbody class="td_color">
                      {notificationList?.users?.length > 0 ? (
                        notificationList?.users?.map((item, i) => (
                          <tr key={i}>
                            <td>{(notificationList?.currentPage - 1) * 10 + i + 1}</td>
                            <td>@{item?.user_name}</td>
                            <td>{item?.subscribe_newsletter == "0" ? "No" : "Yes"}</td>
                            <td>{item?.feature_announcements == "0" ? "No" : "Yes"}</td>
                          </tr>
                        ))) : <NoData />}
                    </tbody>
                  </table>

                  {notificationList?.totalPages > 1 && (
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageCounttotal={
                        Math.ceil(notificationList?.totalPages) || 1
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
