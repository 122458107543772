import { createSlice } from "@reduxjs/toolkit";
import { getPushNotificationData } from "../Actions/notificationData";

const initialState = {
  loading: false,
  notifiactionDataList: [],
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPushNotificationData.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.notifiactionDataList = payload.data;
        } else if (!payload.success) {
        }
      })
  },
});

export default NotificationSlice.reducer;
