import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";

export default function Dashboard() {
  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12} className="">
            <div className="main-title-box">
              <div className="title-head-left">
                <span>Pages / Dashboard</span>
                <h4>Main Dashboard</h4>
              </div>
              <div className="title-pro-right">
                <div className="">
                  <Link to="#" className="waves-effect">
                    <i className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_2242_20999)">
                          <path d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z" fill="#C0C0C0" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2242_20999">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                  />
                </div>
              </div>
            </div>
            <div className="dashboard-data-no">
              <p>Dashboard KPIS TBC</p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
