import { createSlice } from "@reduxjs/toolkit";
import { getListOfSales, getSalesData, getSalesDataById } from "../Actions/salesData";

const initialState = {
  loading: false,
  salesDataLists: [],
  salesDetail: [],
  salesData: []
};

const SaleSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSalesData.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.salesDataLists = payload.data;
        } else if (!payload.success) {
        }
      })

      // ============================================================================

      .addCase(getSalesDataById.fulfilled, (state, { payload }) => {
        if (payload) {
          state.success = true;
          state.salesDetail = payload.data;
        } else if (!payload.success) {
        }
      })

            // ============================================================================

            .addCase(getListOfSales.fulfilled, (state, { payload }) => {
              if (payload) {
                state.success = true;
                state.salesData = payload.data;
              } else if (!payload.success) {
              }
            })
  },
});

export default SaleSlice.reducer;
