import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { listSubAdminOrUser } from "../Redux/Actions/auth";
import { formatDateToDMY } from "../Utils/commonFiles";
import ConfirmModal from "../ modals/ConfirmModal";
import NoData from "../components/NoData";

export default function AdminSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const list = useSelector((e) => e.userAuth.listOfAdminOrSubAdmin);

  useEffect(() => {
    dispatch(listSubAdminOrUser());
  }, [dispatch]);

  let permission = JSON.parse(sessionStorage.getItem("permission"));

  const handleteacherDelete = (id, type) => {
    setModalOpen(true);
    setSelectedType(type);
    setSelectedId(id);
  };

  const handleConfirmClose = async () => {
    setModalOpen(false);
    setSelectedType("");
    setSelectedId(null);
  };

  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span> Administrators</span>
                <h4>All Administrators</h4>
              </div>
              <div className="title-pro-right">
                {permission ? (
                  <ul>
                    <li className="actve">
                      <button onClick={() => navigate("/create-admin")}>
                        Create New
                      </button>
                    </li>
                  </ul>
                ) : (
                  ""
                )}

                <div className="">
                  <Link to="#" className="waves-effect">
                    <i className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_2242_20999)">
                          <path d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z" fill="#C0C0C0" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2242_20999">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mb-4 search-bar">
          <div className="">
            <div className="">
              <div>
                <input
                  type="search"
                  placeholder="Search"
                  className="input-common-properties  "
                />
              </div>
            </div>
          </div>
          <div className="">
            <div>
              <button type="submit" className="export-btn">
                Export
              </button>
            </div>
          </div>
        </div>

        <Row>
          <Col lg={12}>
            <div class="card min-vh-80">
              <div class="card-body position-relative">
                <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                  <h4 class="title_text">Listing </h4>
                </div>

                <div class="table-responsive">
                  <table id="" class="table dt-responsive nowrap ">
                    <thead>
                      <tr>
                        <th className="id-field">S.No</th>
                        <th className="id-field">Name</th>
                        <th className="id-field">Email</th>
                        <th className="id-field">Created</th>
                        <th className="id-field">Role</th>
                        <th className="id-field">Access Level</th>
                        {permission ? (
                          <th className="id-field">Actions</th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    <tbody class="td_color">
                      {list?.length > 0 ? (
                        list?.map((item, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{item?.name}</td>
                            <td>{item?.email}</td>
                            <td>{formatDateToDMY(item?.createdAt)}</td>
                            <td>{item?.role}</td>
                            <td>
                              {!item?.full_access ? "View Only" : "Full Access"}
                            </td>
                            {/* /edit-admin/:i */}

                            {permission ? (
                              <>
                                <td>
                                  <div className="action-btn-set">
                                    <button onClick={() => navigate(`/edit-admin/${item._id}`)}>
                                      Edit
                                    </button>

                                    <button
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleteacherDelete(item._id, "adminDelete")
                                      }
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                        ))
                      ) : (
                        <NoData />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <ConfirmModal
        data={selectedId}
        isModalOpen={isModalOpen}
        selectedType={selectedType}
        handleConfirmClose={handleConfirmClose}
      />
    </Layout>
  );
}
