import React from "react";
import { Container } from "react-bootstrap";

export default function BuyerProtection() {
  return (
    <Container>
      <div class="privacy_text">
        <div class="header-logo">
          <a class="terms-and-conditions link" href="#">
            <img
              src={
                require("../assets/images/new-logo-ex.png")
              }
            />
          </a>
          <h2>Purchase Protection</h2>
        </div>
        <h3>What is the Purchase Protection Fee?</h3>
        <p>
          The purchase protection fee ensures a safe and secure experience on
          Wedged. It is automatically added to all purchases made with the "Buy
          Now" button.
        </p>
        <h4>What Does It Cover?</h4>
        <ul>
          <li>
            <b>Refunds</b>: You can return an item if it's damaged or
            significantly different from the description. If the item is lost or
            not delivered, you’re eligible for a refund. Buyers must report
            issues within 48 hours of delivery (or delivery notification).
            Return postage is paid for by the buyer.
          </li>

          <li>
            <b>Secure Payments</b>: Payments are handled by our trusted partner
            using an industry-leading encryption standard. Funds are only
            released to the seller once the item is delivered and the buyer has
            confirmed there are no issues. Payment is automatically released 48
            hours after delivery confirmation.
          </li>

          <li>
            <b>24/7 Support</b>: Our support team is available anytime through
            Wedged messages for any questions or concerns.
          </li>
        </ul>
        <h4>How is it Calculated?</h4>

        <ul>
          <li>
            For orders under £500 (incl. VAT): 7% of the item price (excluding
            postage) + 50p.
          </li>
          <li>
            For orders over £500 (incl. VAT): 5% of the item price (excluding
            postage).
          </li>
        </ul>

        <p>
          For more details on Purchase Protection take a look in the profile
          section of the Wedged app or on the Wedged website.
        </p>
      </div>
    </Container>
  );
}
