

import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

export const getPushNotificationData = createAsyncThunk("getPushNotificationData", async ({ page, limit, search }) => {
    const response = await API.get('push_notifications_list', {
      params: { page, limit, search }
    });
    return response.data;
  });
