import { Link, useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import {
  getCategory,
  getListing,
  getSubCategory,
} from "../Redux/Actions/userData";
import { formatDateToDMY } from "../Utils/commonFiles";
import NoData from "../components/NoData";
import profile from "../assets/images/place-holder.png";
import Pagination from "../components/Pagination";
import { setCurrentPage } from "../Redux/Reducers/authSlice";

export default function Listings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('');
  const currentPage = useSelector((state) => state.userAuth.currentPage);

  const Image_url = process.env.REACT_APP_IMG_URL + "/uploads/";

  const itemList = useSelector((e) => e.itemAuth.itemList);
  const categoryList = useSelector((e) => e.itemAuth.categoryList);
  const subcategoryList = useSelector((e) => e.itemAuth.subCategoryList);

  // useEffect(() => {
  //   dispatch(startstopLoading(true));
  //   dispatch(getListing({ page: 1, limit: 10 })).then((res) => {
  //     if (res?.payload?.success == true || res?.payload?.success == false) {
  //       dispatch(getCategory());
  //       dispatch(startstopLoading(false));
  //     }
  //   });
  // }, [dispatch]);

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(
      getListing({
        page: currentPage,
        limit: 10,
        searchTerm,
        category: selectedCategoryId,
        subCategory: selectedSubCategoryId,
      })
    ).then((res) => {
      if (res?.payload?.success) {
        dispatch(getCategory());
        dispatch(startstopLoading(false));
      }
    });
  }, [dispatch, searchTerm, selectedCategoryId, selectedSubCategoryId, currentPage]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };



  const handleCategoryChange = (event) => {
    const selectedId = event.target.value;
    setSelectedCategoryId(selectedId);
    setSelectedSubCategoryId('');
    if (selectedId) {
      dispatch(getSubCategory(selectedId));
    }
  };

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategoryId(event.target.value);
  };


  const handlePageClick = (selected) => {
    dispatch(
      getListing({
        page: selected.selected + 1,
        limit: 10,
        searchTerm,
        category: selectedCategoryId,
        subCategory: selectedSubCategoryId,
      })
    );

    navigate(`/listings?page=${selected.selected + 1}`);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const page = query.get('page') || 1;
    dispatch(setCurrentPage(parseInt(page, 10)))
  }, [location.search]);

  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span>Listings</span>
                <h4>All Listings </h4>
              </div>
              <div className="title-pro-right">
                <div className="">
                  <Link to="#" className="waves-effect">
                    <i className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_2242_20999)">
                          <path d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z" fill="#C0C0C0" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2242_20999">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mb-4 search-bar">
          <div className="">
            <div className="">
              <div>
                <input
                  type="search"
                  placeholder="Search"
                  className="input-common-properties"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            {/* <div className="">
              <div>
                <select className="input-common-properties bg-white ">
                  <option className="input-common-properties ">
                    Sold Status
                  </option>
                  <option className="input-common-properties ">-</option>
                </select>
              </div>
            </div> */}
            <div className="p-0">
              <div>
                <select
                  className="input-common-properties bg-white"
                  onChange={handleCategoryChange}
                  value={selectedCategoryId}
                >
                  <option className="input-common-properties" value="">
                    Category
                  </option>
                  {categoryList?.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.category_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="p-0">
              <div>
                <select
                  className="input-common-properties bg-white"
                  onChange={handleSubCategoryChange}
                  value={selectedSubCategoryId}
                >
                  <option className="input-common-properties" value="">
                    Sub Category
                  </option>
                  {subcategoryList?.map((subcategory) => (
                    <option key={subcategory._id} value={subcategory._id}>
                      {subcategory.sub_category_name}
                    </option>
                  ))}
                </select>

              </div>
            </div>
            {/* <div className="p-0">
              <div>
                <select className="input-common-properties bg-white">
                  <option className="input-common-properties ">
                    Offer Status
                  </option>
                  <option className="input-common-properties ">-</option>
                </select>
              </div>
            </div> */}
          </div>
          <div className="">
            <div>
              <button type="submit" className="export-btn">
                Export
              </button>
            </div>
          </div>
        </div>
        <Row>
          <Col lg={12}>
            <div class="card min-vh-80">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                  <h4 class="title_text">
                    Listings ({itemList?.totalItems || "0"})
                  </h4>
                </div>

                <div class="table-responsive">
                  <table
                    id=""
                    class="table dt-responsive commom-tables nowrap w-100"
                  >
                    <thead>
                      <tr>
                        <th className="">#</th>
                        <th className="">Listed</th>
                        <th className="">Image</th>
                        <th className="">Item Name</th>
                        <th className="">Category </th>
                        <th className="">Sub Category</th>
                        <th className="">List Price</th>
                        <th className="">Offers</th>
                        <th className="">Status </th>
                        {/* <th className="">Sale Price </th> */}
                        <th className="">Seller</th>
                        {/* <th className="">Buyer</th> */}
                      </tr>
                    </thead>
                    <tbody class="td_color">
                      {itemList?.items?.length > 0 ? (
                        itemList?.items?.map((item, i) => (
                          <tr key={i}>
                            <td>{(itemList?.currentPage - 1) * 10 + i + 1}</td>
                            <td>{formatDateToDMY(item?.createdAt)}</td>
                            <td>
                              <Link to={`/listing-details?item_id=${item?._id}&payment_intent_id=${item?.paymentIntentId}&number=${(itemList?.currentPage - 1) * 10 + i + 1}`}>
                                <img
                                  src={
                                    item?.item_Image[0]
                                      ? item?.item_Image[0].startsWith(
                                        "uploads/"
                                      )
                                        ? process.env.REACT_APP_IMG_URL +
                                        "/" +
                                        item.item_Image[0]
                                        : Image_url + item.item_Image[0]
                                      : profile
                                  }
                                  alt=""
                                  className="list-image"
                                />
                              </Link>
                            </td>
                            <td className="no-wrap">
                              {" "}
                              <Link to={`/listing-details?item_id=${item?._id}&payment_intent_id=${item?.paymentIntentId}&number=${(itemList?.currentPage - 1) * 10 + i + 1}`}>
                                {item?.item_Title ? item?.item_Title : "-"}
                              </Link>
                            </td>
                            <td>{item?.category?.category_name ? item?.category?.category_name : "-"}</td>
                            <td>{item?.sub_Category?.sub_category_name ? item?.sub_Category?.sub_category_name : "-"}</td>
                            {/* <td>{item?.price !== undefined ? `£ ${item.price.toFixed(2)}` : "-"}</td> */}
                            <td>£ {item?.price !== undefined ? item.price.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td>


                            <td>{item?.totalOffers}</td>
                            <td>Item Listed</td>
                            {/* <td>£ {item?.price !== undefined ? item.price.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}</td> */}
                            <td  >
                              <Link
                                to={`/user-overview/${item?.user_id?._id}/${item?.user_id?.user_name}`}
                              >
                                {item?.user_id?.user_name
                                  ? `@${item?.user_id?.user_name}`
                                  : "-"}{" "}

                              </Link>
                            </td>

                            {/* <td>-</td> */}
                          </tr>
                        ))
                      ) : (
                        <NoData />
                      )}
                    </tbody>
                  </table>

                  {itemList?.totalPages > 1 && (
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageCounttotal={
                        Math.ceil(itemList?.totalPages) || 1
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
