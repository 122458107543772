import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

export const getSalesData = createAsyncThunk("getSalesData", async (data) => {
  const response = await API.post('getSucceededPayments', data);
  return response.data;
});

export const getSalesDataById = createAsyncThunk("getSalesDataById", async (data) => {
  const response = await API.post('getsalesdetails', data);
  return response.data;
});

export const getListOfSales = createAsyncThunk("getListOfSales", async (data) => {
  const response = await API.post('getSucceededPayments', data);
  return response.data;
});

export const salesDisputedIssueStatus = createAsyncThunk("salesDisputedIssueStatus", async (data) => {
  const response = await API.post('resolved_issue', data);
  return response.data;
});