import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";

export default function CreateNotification() {
  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span> Notification</span>
                <h4>Create Push Notification </h4>
              </div>
              <div className="title-pro-right">
                <ul>
                  <li className="active">
                    <a >Create New</a>
                  </li>
                </ul>

                <div className="">
                  <Link to="#" className="waves-effect">
                    <i className="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_2242_20999)">
    <path d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z" fill="#C0C0C0"/>
  </g>
  <defs>
    <clipPath id="clip0_2242_20999">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mb-4 search-bar">
          <div className="">
            <div className="">
              <div>
                <input
                  type="search"
                  placeholder="Search"
                  className="input-common-properties  "
                />
              </div>
            </div>
          </div>
        </div>

        <Row>
          <Col lg={5}>
            <div className="card">
              <div className="card-body details-card ">
                <div className="">
                  <h4 className="wallet-heading m-0">
                    Create Push Notification
                  </h4>
                </div>

                <div className="form-details py-2">
                  <form className="w-100">
                    <div className=" form-floating mb-2  ">
                      <input
                        type="text"
                        className="form-control form-details-input "
                        id="floatingInputValue"
                        placeholder="John Smith"
                      />
                      <label
                        for="floatingInputValue"
                        className="form-details-label"
                      >
                        Campaign Name
                      </label>
                    </div>
                    <div className="mb-2 textarea-bio">
                      <div className=" form-floating ">
                        <textarea
                          rows={5}
                          className="form-control form-details-input form-details-textarea w-100"
                          id="floatingInputValue"
                          placeholder="John Smith"
                          // value=""
                        />
                        <label
                          for="floatingInputValue"
                          className="form-details-label"
                        >
                          Notification Details
                        </label>
                      </div>
                    </div>
                    {/* <div className="form-floatig mb-2">
                      <label className="form-details-label">
                        Campaign Name
                      </label>
                      <div>
                        <input
                          type="checkbox"
                          className="form-cotrol form-details-input"
                          id="floatingInputVlue"

                          value="Maddison"
                        />
                        <label className="form-details-label">IOS </label>
                      </div>
                                            
                      <label
                        for="floatingInputValue"
                        className="form-details-label"
                      >
                        
                      </label>
                    </div> */}
                    <div className="card wallet-card account-card mb-2">
                      <div className="card-body">
                        <p className="m-0">Campaign Name</p>
                        <div className="checkbox-input">
                          <input className="" type="checkbox"></input>
                          <label className="form-details-input shadow-none ps-1 pe-3">ios</label>
                          <input className="" type="checkbox"></input>
                          <label className="form-details-input shadow-none ps-1">Android</label>
                        </div>
                      </div>
                    </div>
                    <div className="schedule-btn">
                      <button type="submit" className="submit-btn">
                        Schedule
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={5}>
            <div className="card">
              <div className="card-body details-card ">
                <div className="">
                  <h4 className="wallet-heading m-0">Publish Status</h4>
                </div>

                <div className="form-details py-2">
                  <form className="w-100">
                    <div>
                      <select className="input-common-properties bg-white w-100">
                        <option className="input-common-properties ">
                          Draft
                        </option>
                        <option className="input-common-properties ">
                          Active
                        </option>
                        <option className="input-common-properties ">
                          Sold Complete
                        </option>
                      </select>
                    </div>
                    <div className="schedule-btn">
                      <button type="submit" className="submit-btn">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
