import { createSlice } from "@reduxjs/toolkit";
import { getMailingData } from "../Actions/mailingData";

const initialState = {
  loading: false,
  mailingDataList: [],
};

const MailingSlice = createSlice({
  name: "mailing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMailingData.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.mailingDataList = payload.data;
        } else if (!payload.success) {
        }
      })
  },
});

export default MailingSlice.reducer;
