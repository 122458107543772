import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { userDetailById } from "../Redux/Actions/auth";

export default function CommanUserNav() {
  const navigate = useNavigate();
  const { id, name } = useParams();
  const dispatch = useDispatch();
  const userData = useSelector((e) => e.userAuth.userDetail);
  useEffect(() => {
    dispatch(userDetailById({ id: userData?._id}))
  }, [dispatch]);

  return (
    <ul>
      <li className="active">
        <button
          onClick={() => navigate(`/user-overview/${id}/${name}`)}
          className={
            window.location.pathname ===`/user-overview/${id}/${name}` ? "active" : ""
          }
        >
          Overview
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/user-list/${id}/${name}`)}
          className={
            window.location.pathname ===`/user-list/${id}/${name}` ? "active" : ""
          }
        >
          Listings
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/user-sold/${id}/${name}`)}
          className={
            window.location.pathname ===`/user-sold/${id}/${name}` ? "active" : ""
          }
        >
          Sold
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/user-purchases/${id}/${name}`)}
          className={
            window.location.pathname ===`/user-purchases/${id}/${name}` ? "active" : ""
          }
        >
          Purchases
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/usertransaction/${id}/${name}`)}
          className={
            window.location.pathname ===`/usertransaction/${id}/${name}`
              ? "active"
              : ""
          }
        >
          Transactions
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/user-reviews/${id}/${name}`)}
          className={
            window.location.pathname ===`/user-reviews/${id}/${name}` ? "active" : ""
          }
        >
          Reviews
        </button>
      </li>
    </ul>
  );
}
