
import React from "react";
import { Col, Row } from "react-bootstrap";

const ChatComponent = ({ salesData }) => {
    return (
        <Row className="mb-4 ">
            <Col lg={12}>
                <div className="card purchase-card">
                    <div className="card-body profile-crd listing-details-card buyers-card">
                        <h2>Transcript </h2>
                        <div className="chat-show-bot">
                            {salesData?.chat?.map((chat, index) => {
                                const isSender = chat.chat_type == 2;

                                return (
                                    <ul key={index} className={isSender ? "right-side-bot" : "left-side-bot"}>
                                        <li>
                                            <div className="chat-bot-view">
                                                {isSender ? (
                                                    <>
                                                        <div>{chat.message}</div>
                                                        <img src={require("../assets/images/place-holder.png")}  alt={chat.sender_id.user_name} 
                                                          style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                           <img src={require("../assets/images/place-holder.png")}  alt={chat.sender_id.user_name} 
                                                             style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                           />
                                                        <div>{chat.message}</div>
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                );
                            })}
                        </div>

                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default ChatComponent;


        {/* <Row className="mb-4 ">


          <Col lg={12}>
            <div className="card purchase-card">
              <div className="card-body profile-crd listing-details-card buyers-card">
                <h2>Transcript </h2>
                <div className="chat-show-bot">
                  <ul className="left-side-bot">
                    <li>
                      <div className="chat-bot-view">
                        <img src={require("../assets/images/person.png")} />
                        <div>
                          Hey! Could you tell me a bit more about this item?
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul className="right-side-bot">
                    <li>
                      <div className="chat-bot-view">
                        <div>
                          Hey! Could you tell me a bit more about this item?
                        </div>
                        <img src={require("../assets/images/person.png")} />
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </Col>


        </Row> */}