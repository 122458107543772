

import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

export const getMailingData = createAsyncThunk("getMailingData", async ({ page, limit, search }) => {
    const response = await API.get('get_mailing_list', {
      params: { page, limit, search }
    });
    return response.data;
  });

