import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { setCurrentPage } from "../Redux/Reducers/authSlice";
import { useDispatch } from "react-redux";

export default function Sidebar({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const cookies = new Cookies();

  // const isHref = (
  //   pageStr,
  //   secondPageStr,
  //   thirdStr,
  //   fouthStr,
  //   fifthStr,
  //   sixth,
  //   seventh
  // ) => {
  //   if (
  //     window.location.href.includes(pageStr) ||
  //     window.location.href.split("/").includes(secondPageStr) ||
  //     window.location.href.split("/").includes(thirdStr) ||
  //     window.location.href.split("/").includes(fouthStr) ||
  //     window.location.href.split("/").includes(fifthStr) ||
  //     window.location.href.split("/").includes(sixth) ||
  //     window.location.href.split("/").includes(seventh)
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const isHref = (
    pageStr,
    secondPageStr,
    thirdStr,
    fourthStr,
    fifthStr,
    sixth,
    seventh
  ) => {
    const path = window.location.pathname; 
  
    if (
      path.includes(pageStr) ||
      path.includes(secondPageStr) ||
      path.includes(thirdStr) ||
      path.includes(fourthStr) ||
      path.includes(fifthStr) ||
      path.includes(sixth) ||
      path.includes(seventh)
    ) {
      return true;
    } else {
      return false;
    }
  };
  

  const handleSignOut = async (e) => {
    e.preventDefault();
    cookies.remove("adminToken");
    sessionStorage.removeItem("adminToken");
    sessionStorage.removeItem("permission")
    navigate("/");
  };

  return (
    <>
      <div className="layout-wrapper">
        <div id="sidebar-main">
          <div className="h-100">
            <div id="sidebar-menu">
              <div className="sidebar-logo">
                <Link to="">
                  <img
                    src={require("../assets/images/logo-main.svg").default}
                    alt="logo"
                  />
                </Link>
              </div>
              <ul className="sidebar-list-menu list-unstyled ">
                <div>
                  <li className={isHref("dashboard") ? "tab_active" : ""}>
                    <Link to="/dashboard">
                      <i>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3.62791C2 2.72884 2.72884 2 3.62791 2H9.2093C10.1084 2 10.8372 2.72884 10.8372 3.62791V9.2093C10.8372 10.1084 10.1084 10.8372 9.2093 10.8372H3.62791C2.72884 10.8372 2 10.1084 2 9.2093V3.62791Z" fill="#A3AED0" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1626 3.62791C13.1626 2.72884 13.8914 2 14.7905 2H20.3719C21.271 2 21.9998 2.72884 21.9998 3.62791V9.2093C21.9998 10.1084 21.271 10.8372 20.3719 10.8372H14.7905C13.8914 10.8372 13.1626 10.1084 13.1626 9.2093V3.62791Z" fill="#A3AED0" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 14.7907C2 13.8917 2.72884 13.1628 3.62791 13.1628H9.2093C10.1084 13.1628 10.8372 13.8917 10.8372 14.7907V20.3721C10.8372 21.2712 10.1084 22.0001 9.2093 22.0001H3.62791C2.72884 22.0001 2 21.2712 2 20.3721V14.7907Z" fill="#A3AED0" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1626 14.7907C13.1626 13.8917 13.8914 13.1628 14.7905 13.1628H20.3719C21.271 13.1628 21.9998 13.8917 21.9998 14.7907V20.3721C21.9998 21.2712 21.271 22.0001 20.3719 22.0001H14.7905C13.8914 22.0001 13.1626 21.2712 13.1626 20.3721V14.7907Z" fill="#A3AED0" />
                        </svg>

                      </i>
                      <span>Dashboard</span>
                    </Link>
                  </li>

                  <li className={isHref("listings", "listing-details") ? "tab_active" : " "}>
                    <Link to="/listings" className="">
                      <i className="">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.93293C2.25 3.45119 3.45119 2.25 4.93293 2.25H19.5671C21.0488 2.25 22.25 3.45119 22.25 4.93293V19.5671C22.25 21.0488 21.0488 22.25 19.5671 22.25H4.93293C3.45119 22.25 2.25 21.0488 2.25 19.5671V4.93293ZM6.39634 6.39634C5.85753 6.39634 5.42073 6.83313 5.42073 7.37195C5.42073 7.91076 5.85753 8.34756 6.39634 8.34756H7.37195C7.91076 8.34756 8.34756 7.91077 8.34756 7.37195C8.34756 6.83314 7.91077 6.39634 7.37195 6.39634H6.39634ZM6.39634 11.2744C5.85753 11.2744 5.42073 11.7112 5.42073 12.25C5.42073 12.7888 5.85753 13.2256 6.39634 13.2256H7.37195C7.91076 13.2256 8.34756 12.7888 8.34756 12.25C8.34756 11.7112 7.91077 11.2744 7.37195 11.2744H6.39634ZM6.39634 16.1524C5.85753 16.1524 5.42073 16.5892 5.42073 17.128C5.42073 17.6669 5.85753 18.1037 6.39634 18.1037H7.37195C7.91076 18.1037 8.34756 17.6669 8.34756 17.128C8.34756 16.5892 7.91077 16.1524 7.37195 16.1524H6.39634ZM10.2988 7.37195C10.2988 6.83314 10.7356 6.39634 11.2744 6.39634H18.1037C18.6425 6.39634 19.0793 6.83314 19.0793 7.37195C19.0793 7.91077 18.6425 8.34756 18.1037 8.34756H11.2744C10.7356 8.34756 10.2988 7.91076 10.2988 7.37195ZM10.2988 12.25C10.2988 11.7112 10.7356 11.2744 11.2744 11.2744H18.1037C18.6425 11.2744 19.0793 11.7112 19.0793 12.25C19.0793 12.7888 18.6425 13.2256 18.1037 13.2256H11.2744C10.7356 13.2256 10.2988 12.7888 10.2988 12.25ZM10.2988 17.128C10.2988 16.5892 10.7356 16.1524 11.2744 16.1524H18.1037C18.6425 16.1524 19.0793 16.5892 19.0793 17.128C19.0793 17.6669 18.6425 18.1037 18.1037 18.1037H11.2744C10.7356 18.1037 10.2988 17.6669 10.2988 17.128Z" fill="#A3AED0" />
                        </svg>
                      </i>
                      <span>Listings</span>
                    </Link>
                  </li>

                  <li className={isHref("sales") ? "tab_active" : ""}>
                    <Link onClick={(e) => {
                      e.preventDefault();
                      dispatch(setCurrentPage(1));
                      navigate('/sales?page=1');
                    }} className="">
                      <i className="">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.93293 2.25C3.45119 2.25 2.25 3.45119 2.25 4.93293V19.5671C2.25 21.0488 3.45119 22.25 4.93293 22.25H19.5671C21.0488 22.25 22.25 21.0488 22.25 19.5671V4.93293C22.25 3.45119 21.0488 2.25 19.5671 2.25H4.93293ZM12.9817 7.37195C12.9817 6.96784 12.6541 6.64024 12.25 6.64024C11.8459 6.64024 11.5183 6.96784 11.5183 7.37195V17.128C11.5183 17.5321 11.8459 17.8598 12.25 17.8598C12.6541 17.8598 12.9817 17.5321 12.9817 17.128V7.37195ZM17.8598 11.2744C17.8598 10.8703 17.5321 10.5427 17.128 10.5427C16.724 10.5427 16.3963 10.8703 16.3963 11.2744V17.128C16.3963 17.5321 16.724 17.8598 17.128 17.8598C17.5321 17.8598 17.8598 17.5321 17.8598 17.128V11.2744ZM8.10366 13.2256C8.10366 12.8215 7.77606 12.4939 7.37195 12.4939C6.96784 12.4939 6.64024 12.8215 6.64024 13.2256V17.128C6.64024 17.5321 6.96784 17.8598 7.37195 17.8598C7.77606 17.8598 8.10366 17.5321 8.10366 17.128V13.2256Z" fill="#A3AED0" />
                        </svg>
                      </i>
                      <span>Sales</span>
                    </Link>
                  </li>
                  <li className={isHref("transactions") ? "tab_active" : ""}>
                    <Link to="/transactions" className="">
                      <i className="">

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 1.75C2.98122 1.75 1.75 2.98122 1.75 4.5V19.5C1.75 21.0188 2.98122 22.25 4.5 22.25H19.5C21.0188 22.25 22.25 21.0188 22.25 19.5V4.5C22.25 2.98122 21.0188 1.75 19.5 1.75H4.5ZM10.5303 6.46967C10.8232 6.76256 10.8232 7.23744 10.5303 7.53033L9.81066 8.25H16C16.4142 8.25 16.75 8.58579 16.75 9C16.75 9.41421 16.4142 9.75 16 9.75H9.81066L10.5303 10.4697C10.8232 10.7626 10.8232 11.2374 10.5303 11.5303C10.2374 11.8232 9.76256 11.8232 9.46967 11.5303L7.46967 9.53033C7.17678 9.23744 7.17678 8.76256 7.46967 8.46967L9.46967 6.46967C9.76256 6.17678 10.2374 6.17678 10.5303 6.46967ZM13.4697 12.4697C13.7626 12.1768 14.2374 12.1768 14.5303 12.4697L16.5303 14.4697C16.671 14.6103 16.75 14.8011 16.75 15C16.75 15.1989 16.671 15.3897 16.5303 15.5303L14.5303 17.5303C14.2374 17.8232 13.7626 17.8232 13.4697 17.5303C13.1768 17.2374 13.1768 16.7626 13.4697 16.4697L14.1893 15.75H8C7.58579 15.75 7.25 15.4142 7.25 15C7.25 14.5858 7.58579 14.25 8 14.25H14.1893L13.4697 13.5303C13.1768 13.2374 13.1768 12.7626 13.4697 12.4697Z" fill="#A3AED0" />
                        </svg>

                      </i>
                      <span>Transactions</span>
                    </Link>
                  </li>
                  <li
                    className={
                      isHref(
                        "users",
                        "user-overview",
                        "user-list",
                        "user-sold",
                        "user-purchases",
                        "usertransaction",
                        "user-reviews"
                      )
                        ? "tab_active"
                        : ""
                    }
                  >
                    <Link  
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setCurrentPage(1));
                      navigate('/users?page=1');
                    }}
                    className="">
                      <i className="">

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 19.908C2 16.2224 4.82516 13.163 8.39612 13.163H10.0242C13.5952 13.163 16.4203 16.2224 16.4203 19.908C16.4203 20.2933 16.108 20.6057 15.7225 20.6057H2.69776C2.3124 20.6057 2 20.2933 2 19.908Z" fill="#A3AED0" />
                          <path d="M17.6944 20.6057H21.3023C21.6877 20.6057 22.0001 20.2933 22.0001 19.908C22.0001 16.2224 19.1749 13.163 15.604 13.163H14.3926C16.4809 14.6475 17.8135 17.1575 17.8135 19.908C17.8135 20.1526 17.7715 20.3875 17.6944 20.6057Z" fill="#A3AED0" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25635 7.34825C5.25635 5.16454 7.0266 3.39429 9.21031 3.39429C11.394 3.39429 13.1643 5.16454 13.1643 7.34825C13.1643 9.53197 11.394 11.3022 9.21031 11.3022C7.0266 11.3022 5.25635 9.53197 5.25635 7.34825Z" fill="#A3AED0" />
                          <path d="M13.1641 10.9522C13.6608 11.1771 14.2122 11.3022 14.7928 11.3022C16.9765 11.3022 18.7468 9.53197 18.7468 7.34825C18.7468 5.16454 16.9765 3.39429 14.7928 3.39429C14.2122 3.39429 13.6608 3.51946 13.1641 3.74427C14.0313 4.69508 14.5602 5.9599 14.5602 7.34825C14.5602 8.7366 14.0313 10.0014 13.1641 10.9522Z" fill="#A3AED0" />
                        </svg>

                      </i>
                      <span>Users</span>
                    </Link>
                  </li>

                  <li className={isHref("mailing-list") ? "tab_active" : ""}>
                    <Link to="/mailing-list" className="">
                      <i className="">

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.09866 3.14534C6.45646 3.14534 5.93587 3.66594 5.93587 4.30813V11.75H4.54053V4.30813C4.54053 2.89532 5.68584 1.75 7.09866 1.75H16.4009C17.8138 1.75 18.9591 2.89532 18.9591 4.30813V11.75H17.5637V4.30813C17.5637 3.66594 17.0432 3.14534 16.4009 3.14534H7.09866Z" fill="#A3AED0" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.72656 6.16862C8.72656 5.78331 9.03892 5.47095 9.42423 5.47095H14.0754C14.4607 5.47095 14.773 5.78331 14.773 6.16862C14.773 6.55393 14.4607 6.86629 14.0754 6.86629H9.42423C9.03892 6.86629 8.72656 6.55393 8.72656 6.16862ZM8.72656 9.88953C8.72656 9.50422 9.03892 9.19186 9.42423 9.19186H14.0754C14.4607 9.19186 14.773 9.50422 14.773 9.88953C14.773 10.2748 14.4607 10.5872 14.0754 10.5872H9.42423C9.03892 10.5872 8.72656 10.2748 8.72656 9.88953Z" fill="#A3AED0" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.62545 9.69848L5.73168 7.59225L4.74503 6.60559L2.2268 9.12381C1.92151 9.4291 1.75 9.84317 1.75 10.2749V19.1919C1.75 20.6047 2.89532 21.7501 4.30813 21.7501H19.1918C20.6046 21.7501 21.7499 20.6047 21.7499 19.1919V10.2749C21.7499 9.84317 21.5784 9.4291 21.2731 9.12381L18.7549 6.60559L17.7683 7.59225L19.8745 9.69845L12.27 13.5007C11.9426 13.6644 11.5573 13.6644 11.2299 13.5007L3.62545 9.69848Z" fill="#A3AED0" />
                        </svg>
                      </i>
                      <span>Mailing list</span>
                    </Link>
                  </li>
                  <li className={isHref("feedbacks") ? "tab_active" : ""}>
                    <Link to="/feedbacks" className="">
                      <i className="">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.93293 2.25C3.45119 2.25 2.25 3.45119 2.25 4.93293V19.5671C2.25 21.0488 3.45119 22.25 4.93293 22.25H19.5671C21.0488 22.25 22.25 21.0488 22.25 19.5671V4.93293C22.25 3.45119 21.0488 2.25 19.5671 2.25H4.93293ZM13.4024 7.32153C12.9149 6.41315 11.5843 6.41314 11.0968 7.32153L9.89101 9.56842L7.25542 9.96701C6.2484 10.1193 5.73782 11.3678 6.54624 12.1374L8.41102 13.913L8.00157 16.3815C7.81871 17.484 8.98978 18.1496 9.86411 17.7266L12.2496 16.5726L14.6351 17.7266C15.5094 18.1496 16.6805 17.484 16.4976 16.3815L16.0881 13.913L17.9529 12.1374C18.7614 11.3678 18.2508 10.1193 17.2438 9.96701L14.6081 9.56842L13.4024 7.32153Z" fill="#A3AED0" />
                        </svg>
                      </i>
                      <span>Feedback</span>
                    </Link>
                  </li>
                  <li
                    className={isHref("push-notification") ? "tab_active" : ""}
                  >
                    <Link to="/push-notification" className="">
                      <i className="">

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 9.5C3.75 4.94365 7.44365 1.25 12 1.25C16.5563 1.25 20.25 4.94365 20.25 9.5V11.6716C20.25 12.0031 20.3817 12.321 20.6161 12.5555L22.0303 13.9697C22.171 14.1103 22.25 14.3011 22.25 14.5V15.882C22.25 16.5376 21.8809 17.1647 21.2502 17.4486C19.6095 18.187 16.524 19.25 12 19.25C7.47597 19.25 4.3905 18.187 2.74982 17.4486C2.11907 17.1647 1.75 16.5376 1.75 15.882V14.5C1.75 14.3011 1.82902 14.1103 1.96967 13.9697L3.38388 12.5555C3.6183 12.321 3.75 12.0031 3.75 11.6716V9.5Z" fill="#A3AED0" />
                          <path d="M15.6819 20.6876C15.8239 20.9965 15.7411 21.3734 15.4614 21.5916C14.5304 22.3181 13.3157 22.7492 12 22.7492C10.6844 22.7492 9.46971 22.3181 8.53866 21.5916C8.25901 21.3734 8.17618 20.9965 8.31816 20.6876C8.3632 20.5896 8.47346 20.5484 8.58028 20.563C9.12239 20.6373 10.8318 20.8581 12 20.8581C13.1682 20.8581 14.8777 20.6373 15.4198 20.563C15.5266 20.5484 15.6369 20.5896 15.6819 20.6876Z" fill="#A3AED0" />
                        </svg>
                      </i>
                      <span>Push Notifications</span>
                    </Link>
                  </li>
                </div>

                <div>
                  <li className={isHref("admin-settings", "create-admin") ? "tab_active" : ""}>
                    <Link to="/admin-settings" className="">
                      <i className="">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8124 2C10.1117 2 9.48961 2.44837 9.26803 3.11312L8.6285 5.03172L7.63931 5.65736L5.55411 5.14141C4.85759 4.96907 4.13036 5.27309 3.76373 5.88989L2.49472 8.02486C2.09452 8.69815 2.23053 9.56099 2.81842 10.0785L4.24699 11.3361V12.6639L2.81886 13.9215C2.2311 14.4391 2.09519 15.3018 2.49533 15.9751L3.76441 18.1101C4.13103 18.7269 4.85826 19.0309 5.55479 18.8586L7.66092 18.3375L8.54533 18.8334L9.12195 20.8248C9.3235 21.5209 9.96093 22 10.6856 22H13.3198C14.0446 22 14.682 21.5209 14.8835 20.8248L15.4602 18.8333L16.3439 18.3375L18.45 18.8586C19.1465 19.0309 19.8738 18.7269 20.2404 18.1101L21.5334 15.9347C21.9144 15.2936 21.8112 14.4755 21.2826 13.9493L19.808 12.4811L19.8084 11.5192L21.2833 10.0507C21.8118 9.52445 21.9152 8.70638 21.5341 8.06528L20.241 5.88989C19.8744 5.27309 19.1472 4.96907 18.4507 5.14141L16.3655 5.65736L15.3763 5.03172L14.7368 3.11312C14.5152 2.44837 13.893 2 13.1924 2H10.8124ZM12.0009 6.65116C10.5882 6.65116 9.44282 7.79647 9.44282 9.2093C9.44282 10.6221 10.5882 11.7674 12.0009 11.7674C13.4138 11.7674 14.5591 10.6221 14.5591 9.2093C14.5591 7.79648 13.4138 6.65116 12.0009 6.65116ZM9.34906 15.6052C9.87938 14.6884 10.8689 14.0741 12.0009 14.0741C13.1331 14.0741 14.1226 14.6885 14.6529 15.6051C14.8458 15.9387 15.2726 16.0527 15.6062 15.8597C15.9396 15.6668 16.0536 15.24 15.8607 14.9065C15.0913 13.5763 13.6514 12.6788 12.0009 12.6788C10.3506 12.6788 8.9107 13.5764 8.14124 14.9064C7.9483 15.24 8.06227 15.6668 8.3958 15.8597C8.72933 16.0527 9.15613 15.9387 9.34906 15.6052Z" fill="#A3AED0" />
                        </svg>
                      </i>
                      <span>Admin Settings</span>
                    </Link>
                  </li>
                  <li
                    className={isHref("password-settings") ? "tab_active" : ""}
                  >
                    <Link to="/password-settings" className="">
                      <i className="">

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 3.86029C10.2021 3.86029 8.7446 5.31784 8.7446 7.11581V8.51285H15.2556V7.11581C15.2556 5.31784 13.7981 3.86029 12.0001 3.86029ZM6.88431 7.11581V8.51285H6.41923C5.00645 8.51285 3.8612 9.65821 3.86133 11.071L3.8621 19.4423C3.86223 20.8549 5.00741 22 6.42 22H17.5808C18.9935 22 20.1387 20.8548 20.1387 19.4421V11.0708C20.1387 9.65806 18.9935 8.51285 17.5808 8.51285H17.1159V7.11581C17.1159 4.29043 14.8255 2 12.0001 2C9.17474 2 6.88431 4.29043 6.88431 7.11581ZM16.6508 15.2473C16.6508 14.7336 16.2344 14.3172 15.7207 14.3172C15.207 14.3172 14.7905 14.7336 14.7905 15.2473V15.2566C14.7905 15.7703 15.207 16.1868 15.7207 16.1868C16.2344 16.1868 16.6508 15.7703 16.6508 15.2566V15.2473ZM12.0001 14.3172C12.5138 14.3172 12.9302 14.7336 12.9302 15.2473V15.2566C12.9302 15.7703 12.5138 16.1868 12.0001 16.1868C11.4864 16.1868 11.0699 15.7703 11.0699 15.2566V15.2473C11.0699 14.7336 11.4864 14.3172 12.0001 14.3172ZM9.20967 15.2473C9.20967 14.7336 8.79324 14.3172 8.27953 14.3172C7.76582 14.3172 7.34938 14.7336 7.34938 15.2473V15.2566C7.34938 15.7703 7.76582 16.1868 8.27953 16.1868C8.79324 16.1868 9.20967 15.7703 9.20967 15.2566V15.2473Z" fill="#A3AED0" />
                        </svg>

                      </i>
                      <span>Password Settings</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link onClick={handleSignOut} className="waves-effect">
                      <i className="">

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.5 12.8332C14.0392 12.8332 13.6667 13.2066 13.6667 13.6665V16.9999C13.6667 17.459 13.2933 17.8332 12.8333 17.8332H10.3333V5.33319C10.3333 4.62151 9.87994 3.98569 9.19831 3.74902L8.95159 3.66648H12.8333C13.2933 3.66648 13.6667 4.04062 13.6667 4.49991V6.99991C13.6667 7.45979 14.0392 7.83319 14.5 7.83319C14.9608 7.83319 15.3333 7.45979 15.3333 6.99991V4.49991C15.3333 3.12159 14.2116 1.99992 12.8333 1.99992H3.875C3.84328 1.99992 3.81672 2.0141 3.78589 2.01824C3.74574 2.01488 3.70746 1.99988 3.66672 1.99988C2.74754 1.99992 2 2.74734 2 3.66652V18.6665C2 19.3782 2.45332 20.014 3.13496 20.2507L8.14999 21.9224C8.31999 21.9748 8.48905 21.9999 8.66667 21.9999C9.58584 21.9999 10.3332 21.2523 10.3332 20.3332V19.4999H12.8332C14.2115 19.4999 15.3332 18.3782 15.3332 16.9999V13.6665C15.3332 13.2066 14.9608 12.8332 14.4999 12.8332H14.5Z" fill="#A3AED0" />
                          <path d="M21.7551 9.74437L18.4216 6.4111C18.3051 6.29447 18.1567 6.21503 17.995 6.18281C17.8333 6.1506 17.6658 6.16706 17.5134 6.23012C17.2026 6.35938 16.9992 6.66348 16.9992 7.00024V9.50023H13.666C13.2059 9.50023 12.8325 9.87347 12.8325 10.3335C12.8325 10.7935 13.2059 11.1668 13.666 11.1668H16.9992V13.6668C16.9992 14.0035 17.2026 14.3076 17.5134 14.4369C17.6657 14.4999 17.8333 14.5164 17.995 14.4842C18.1566 14.452 18.3051 14.3726 18.4216 14.256L21.7551 10.9227C22.0809 10.5969 22.0809 10.0702 21.7551 9.74437Z" fill="#A3AED0" />
                        </svg>
                      </i>
                      <span>Logout</span>
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="main-layout">
          <div className="page-content">
            <Container fluid>{children}</Container>
          </div>
        </div>
      </div>
    </>
  );
}
