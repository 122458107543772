import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getSalesDataById, salesDisputedIssueStatus } from "../Redux/Actions/salesData";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import logo from "../assets/images/place-holder.png";
import { formatDateToDMY, genderfunc } from "../Utils/commonFiles";
import moment from "moment";
import { toast } from "react-toastify";
import ChatComponent from "./ChatComponent";
import ListingStatus from "./ListingStatus";
import NoData from "../components/NoData";

export default function SalesListing() {
  const { id, paymentId, roomId, number } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const Image_url = process.env.REACT_APP_IMG_URL + "/uploads/";
  const salesData = useSelector((e) => e.salesAuth.salesDetail);
  const [address, setAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    dispatch(startstopLoading(true));
    const requestData = {
      item_id: id,
      paymentIntentId: paymentId,
      ...(roomId && roomId !== "undefined" && { room_id: roomId })
    };
    dispatch(getSalesDataById(requestData)).then((res) => {
      if (res?.payload) {
        dispatch(startstopLoading(false));
      }
    });
  }, [dispatch, id, roomId]);


  useEffect(() => {
    if (!salesData || !salesData?.item?.sellerDetails) {
      setAddress("");
      return;
    }

    const formatAddress = (details) => {
      if (!details) return "";
      const {
        address1 = "",
        address2 = "",
        city = "",
        country = "",
        post_Code = "",
      } = details;

      return `${address1 ? address1 + "\n" : ""}${address2 ? address2 + "\n" : ""
        }${city ? city + "\n" : ""}${country ? country + "\n" : ""}${post_Code}`;
    };

    const formattedAddress = formatAddress(salesData?.item?.sellerDetails);
    setAddress(formattedAddress.trim());

    if (!salesData || !salesData?.item?.sellerDetails) {
      setAddress1("");
      return;
    }

    const formatAddress1 = (details) => {
      if (!details) return "";
      const {
        address1 = "",
        address2 = "",
        city = "",
        country = "",
        post_Code = "",
      } = details;

      return `${address1 ? address1 + "\n" : ""}${address2 ? address2 + "\n" : ""
        }${city ? city + "\n" : ""}${country ? country + "\n" : ""}${post_Code}`;
    };

    const formattedAddress1 = formatAddress1(salesData?.payment?.userDetails);
    setAddress1(formattedAddress1.trim());


    const formatAddress2 = (details) => {
      if (!details) return "";
      const {
        address1 = "",
        address2 = "",
        city = "",
        country = "",
        post_Code = "",
      } = details;

      return `${address1 ? address1 + "\n" : ""}${address2 ? address2 + "\n" : ""
        }${city ? city + "\n" : ""}${country ? country + "\n" : ""}${post_Code}`;
    };

    const formattedAddress2 = formatAddress2(salesData?.payment?.userDetails);
    setAddress2(formattedAddress2.trim());
  }, [salesData]);


  const issueMessages = {
    1: "Parcel didn’t arrive",
    2: "Parcel arrived at wrong address",
    3: "Parcel was sent back",
    4: "Other issue"
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setStatus(selectedStatus);

    const payload = {
      item_id: id,
      room_id: roomId,
      resolved: selectedStatus
    };

    dispatch(salesDisputedIssueStatus(payload)).then((res) => {
      if (res?.payload) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  function getDomainWithoutTLD(url) {
    if (typeof url !== 'string') return "-";

    const match = url.match(/:\/\/(?:www\.)?([^\/.]+)/);
    return match ? match[1] : "-";
  }

  const OfferStatus = {
    0: "Pending",
    1: "Accepted",
    2: "Declined"
  }


  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span>All Listings/ #{number}</span>
                <div className="user-name-contain">
                  <div className="left-arrow-icon ">
                    <Link onClick={() => navigate(-1)}>
                      <i className="d-flex align-items-center justify-content-center">
                        <svg
                          fill="rgba(204, 210, 227, 1)"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="20px"
                          height="20px"
                          viewBox="0 0 100 100"
                          enable-background="new 0 0 100 100"
                          // xml:space="preserve"
                          stroke="rgba(204, 210, 227, 1)"
                          stroke-width="2.5"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <path d="M33.934,54.458l30.822,27.938c0.383,0.348,0.864,0.519,1.344,0.519c0.545,0,1.087-0.222,1.482-0.657 c0.741-0.818,0.68-2.083-0.139-2.824L37.801,52.564L64.67,22.921c0.742-0.818,0.68-2.083-0.139-2.824 c-0.817-0.742-2.082-0.679-2.824,0.139L33.768,51.059c-0.439,0.485-0.59,1.126-0.475,1.723 C33.234,53.39,33.446,54.017,33.934,54.458z"></path>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </i>
                    </Link>
                  </div>
                  <div className="user-name">
                    <h4>Listing #{number}</h4>
                  </div>
                </div>
              </div>
              <div className="title-pro-right">
                <div className="">
                  <Link to="/Medical-conditions" className="waves-effect">
                    <i className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_2242_20999)">
                          <path d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z" fill="#C0C0C0" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2242_20999">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-4 ">
          <Col lg={9}>
            <div className="card">
              <div className="card-body profile-card listing-details-card">
                <h2>Item Details</h2>
                <div>
                  <div className="title-pro-right itemdetails-likes">
                    <div className="">
                      <Link to="#" className="waves-effect">
                        <i className="">
                          <svg
                            width="22px"
                            height="22px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
                                stroke="#000000"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>{" "}
                            </g>
                          </svg>
                        </i>
                      </Link>
                      <p className="m-0">{salesData?.item?.like_count}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-images">
                {salesData?.item?.item_Image?.map((elem, i) => (
                  <img
                    key={i}
                    src={
                      elem
                        ? elem.startsWith("uploads/")
                          ? process.env.REACT_APP_IMG_URL + "/" + elem
                          : Image_url + elem
                        : logo
                    }
                    alt=""
                    className="list-image"
                  />
                ))}
              </div>
              <Row>
                <div className="listing-details-card">
                  <h2 className="ps-2">Specifics</h2>
                </div>
                <Col className="p-4">
                  <div className="form-details px-2">
                    <form className="">
                      <div className="d-flex gap-5 mb-2">

                        {salesData?.item?.item_Title && (
                          <div className=" form-floating form-inputs  ">
                            <input
                              type="text"
                              className="form-control form-details-input  "
                              id="floatingInputValue"
                              value={salesData?.item?.item_Title}
                              readOnly
                            />
                            <label
                              for="floatingInputValue"
                              className="form-details-label"
                            >
                              Title
                            </label>
                          </div>
                        )}

                        {salesData?.item?.price != null && (
                          <div className="form-floating form-inputs">
                            <input
                              type="text"
                              className="form-control form-details-input"
                              id="floatingInputValue"
                              value={`£${salesData?.item?.price?.toFixed(2)}`}
                              readOnly
                            />
                            <label
                              for="floatingInputValue"
                              className="form-details-label"
                            >
                              List Price
                            </label>
                          </div>
                        )}

                      </div>

                      <div className=" textarea-bio d-flex gap-5 mb-4">
                        {salesData?.item?.desc && (
                          <div className=" form-floating form-inputs">
                            <textarea
                              rows={5}
                              className="form-control form-details-input form-details-textarea "
                              id="floatingInputValue"
                              value={salesData?.item?.desc || "-"}
                              readOnly
                            />
                            <label
                              for="floatingInputValue"
                              className="form-details-label"
                            >
                              Description
                            </label>
                          </div>
                        )}

                        {salesData?.item?.condition && (
                          <div className="form-floating form-inputs">
                            <div className="">
                              <div className=" form-floating mb-2">
                                <input
                                  type="text"
                                  className="form-control form-details-input"
                                  id="floatingInputValue"
                                  placeholder="-"
                                // value="Yes"
                                />
                                <label
                                  for="floatingInputValue"
                                  className="form-details-label"
                                >
                                  Condition
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>


                      <Row className="">
                        {salesData?.item?.category && (
                          <Col lg={6} className="mb-2">
                            <div className="form-floating ">
                              <input
                                type="text"
                                className="form-control form-details-input"
                                id="floatingInputValue"
                                value={salesData?.item?.category || "-"}
                                readOnly
                              />
                              <label htmlFor="floatingInputValue" className="form-details-label">
                                Category
                              </label>
                            </div>
                          </Col>
                        )}

                        {salesData?.item?.dexterity && (
                          <Col lg={6} className="mb-2">
                            <div className="form-floating ">
                              <input
                                type="text"
                                className="form-control form-details-input"
                                id="floatingInputValue"
                                placeholder="24"
                                value={salesData?.item?.dexterity || "-"}
                              />
                              <label htmlFor="floatingInputValue" className="form-details-label">
                                Dexterity
                              </label>
                            </div>
                          </Col>
                        )}



                        {salesData?.item?.sub_Category && (
                          <Col lg={6} className="mb-2">
                            <div className="form-floating ">
                              <input
                                type="text"
                                className="form-control form-details-input"
                                id="floatingInputValue"
                                value={salesData?.item?.sub_Category || "-"}
                                readOnly
                              />
                              <label htmlFor="floatingInputValue" className="form-details-label">
                                Sub Category
                              </label>
                            </div>
                          </Col>
                        )}

                        {salesData?.item?.flex && (
                          <Col lg={6} className="mb-2">
                            <div className="form-floating ">
                              <input
                                type="text"
                                className="form-control form-details-input"
                                id="floatingInputValue"
                                value={salesData?.item?.flex || "-"}
                                readOnly
                              />
                              <label htmlFor="floatingInputValue" className="form-details-label">
                                Flexibility
                              </label>
                            </div>
                          </Col>
                        )}



                        {salesData?.item?.brand && (
                          <Col lg={6} className="mb-2">
                            <div className="form-floating ">
                              <input
                                type="text"
                                className="form-control form-details-input"
                                id="floatingInputValue"
                                value={salesData?.item?.brand || "-"}
                              />
                              <label htmlFor="floatingInputValue" className="form-details-label">
                                Brand
                              </label>
                            </div>
                          </Col>
                        )}

                        {salesData?.item?.loft && (
                          <Col lg={6} className="mb-2">
                            <div className="form-floating ">
                              <input
                                type="text"
                                className="form-control form-details-input"
                                id="floatingInputValue"
                                value={salesData?.item?.loft || "-"}
                              />
                              <label htmlFor="floatingInputValue" className="form-details-label">
                                Loft
                              </label>
                            </div>
                          </Col>
                        )}


                        {(salesData?.item?.gender !== null && salesData?.item?.gender !== undefined) && (
                          <Col lg={6} className="mb-2">
                            <div className="form-floating">
                              <input
                                type="text"
                                className="form-control form-details-input"
                                id="floatingInputValue"
                                value={genderfunc(salesData?.item?.gender)}
                                readOnly
                              />
                              <label htmlFor="floatingInputValue" className="form-details-label">
                                Gender
                              </label>
                            </div>
                          </Col>
                        )}


                      </Row>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <ListingStatus salesData={salesData} />

        </Row>
        <Row className="mb-4">
          <Col lg={7}>
            <div className="card  seller-card">
              <div className="card-body profile-card listing-details-card">
                <h2>Seller Details</h2>
              </div>
              <div className="padding_">
                <div className="seller-img col-2">
                  <img src={require("../assets/images/place-holder.png")} />
                </div>
                <div className="col-9">
                  <div className="address-textarea mb-2 ">
                    <div className="form-floating w-100">
                      <div className="">
                        <div className=" form-floating  mb-2">
                          <input
                            type="text"
                            className="form-control form-details-input"
                            id="floatingInputValue"
                            placeholder="-"
                            value={
                              salesData?.item?.sellerDetails?.name
                                ? salesData?.item?.sellerDetails?.name
                                : "-"
                            }
                            readOnly
                          />
                          <label
                            for="floatingInputValue"
                            className="form-details-label"
                          >
                            Name
                          </label>
                        </div>
                        <div className="form-floating ">
                          <input
                            type="text"
                            className="form-control form-details-input"
                            id="floatingInputValue"
                            placeholder="@johnsmith"
                            value={
                              salesData?.item?.sellerDetails?.user_name
                                ? `@${salesData?.item?.sellerDetails?.user_name}`
                                : "-"
                            }
                            readOnly
                          />
                          <label
                            for="floatingInputValue"
                            className="form-details-label"
                          >
                            Username
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className=" form-floating w-100">
                      <textarea
                        rows={6}
                        className="form-control form-details-input form-details-textarea "
                        id="floatingInputValue"
                        placeholder=" 124 City Road  Manchester
                                West Yorkshire
                                SW1V 2NX"
                        value={address ? address : "-"}
                        readOnly
                      />
                      <label
                        for="floatingInputValue"
                        className="form-details-label"
                      >
                        Address
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={5}>
            <div className="card offer-card">
              <div className="card-body listing-details-card ">
                <h2>Offers</h2>
                <div>
                  <div class="table-responsive offer-table-overflow  ">
                    <table
                      id=""
                      class="table recent-likes-table nowrap offers-table "
                    >
                      <thead>
                        <tr>
                          <th className="id-field">Date</th>
                          <th className="id-field">User</th>
                          <th className="id-field">Amount</th>
                          <th className="id-field">Type </th>
                          <th className="id-field">Status </th>
                        </tr>
                      </thead>
                      <tbody class="td_color">
                        {salesData?.offers?.length > 0 ? salesData?.offers?.map((ele, i) => (
                          <tr key={i}>
                            <td>
                              {moment(ele?.createdAt).format(
                                "DD/MM/YYYY hh:mm A"
                              )}
                            </td>
                            <td>@{ele?.buyer_name || "-"}</td>
                            <td>£{ele?.offer_price?.toFixed(2)}</td>
                            <td>{ele?.type || "-"}</td>
                            <td>{OfferStatus[ele?.offer_status]}</td>
                          </tr>
                        )) : <NoData />}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-4 ">
          <Col lg={7}>
            <div className="card seller-card">
              <div className="card-body profile-crd listing-details-card buyers-card">
                <h2>Buyer Details</h2>
                {/* <div className="no-content ">
                  <p>No details to show yet</p>
                </div> */}
              </div>
              <div className="padding_">
                <div className="seller-img col-2">
                  <img src={require("../assets/images/place-holder.png")} />
                </div>
                <div className="col-9">
                  <div className="address-textarea mb-2 ">
                    <div className="form-floating w-100">
                      <div className="">
                        <div className=" form-floating  mb-2">
                          <input
                            type="text"
                            className="form-control form-details-input"
                            id="floatingInputValue"
                            placeholder="-"
                            value={
                              salesData?.payment?.userDetails?.name
                                ? salesData?.payment?.userDetails?.name
                                : "-"
                            }
                            readOnly
                          />
                          <label
                            for="floatingInputValue"
                            className="form-details-label"
                          >
                            Name
                          </label>
                        </div>
                        <div className="form-floating ">
                          <input
                            type="text"
                            className="form-control form-details-input"
                            id="floatingInputValue"
                            placeholder="@johnsmith"
                            value={
                              salesData?.payment?.userDetails.user_name
                                ? `@${salesData?.payment?.userDetails.user_name}`
                                : "-"
                            }
                            readOnly
                          />
                          <label
                            for="floatingInputValue"
                            className="form-details-label"
                          >
                            Username
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className=" form-floating w-100">
                      <textarea
                        rows={6}
                        className="form-control form-details-input form-details-textarea "
                        id="floatingInputValue"
                        placeholder=" 124 City Road
                                Manchester
                                West Yorkshire
                                SW1V 2NX"
                        value={address1 ? address1 : "-"}
                        readOnly
                      />
                      <label
                        for="floatingInputValue"
                        className="form-details-label"
                      >
                        Address
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-4 ">
          <Col lg={12}>
            <div className="card purchase-card">
              <div className="card-body profile-crd listing-details-card buyers-card">
                <h2 className="mb-2">Purchase Details</h2>
                {/* <div className="no-content">
                  <p>No details to show yet</p>
                </div> */}

                <Row>
                  <Col lg={4}>
                    <div>
                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">Sale Price</p>
                          <h4 className=""> £{salesData?.payment?.normal_price?.toFixed(2)}</h4>
                        </div>
                      </div>
                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">Postage</p>
                          <h4 className=""> £{salesData?.payment?.postage_fee?.toFixed(2)}</h4>
                        </div>
                      </div>
                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">Buyer Protection</p>
                          <h4 className=""> £{salesData?.payment?.protection_fee?.toFixed(2)}</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="">
                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">TOTAL</p>
                          <h4 className=""> £{salesData?.payment?.amount?.toFixed(2)}</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div>
                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">Payment Method</p>
                          <h4 className="">-</h4>
                        </div>
                      </div>
                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">Payment Ref</p>
                          <h4 className="">{paymentId}</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-4 ">
          <Col lg={12}>
            <div className="card purchase-card">
              <div className="card-body profile-crd listing-details-card buyers-card">
                <h2 className="mb-2">Delivery </h2>
                {/* <div className="no-content ">
                  <p>No details to show yet</p>
                </div> */}
                <Row>
                  <Col lg={4}>
                    <div>
                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">Courier Service</p>
                          <h4 className="">{getDomainWithoutTLD(salesData?.shippingLabel?.tracking_url_provider)}</h4>
                        </div>
                      </div>

                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">Cost</p>
                          <h4 className=""> £{salesData?.payment?.protection_fee?.toFixed(2)}</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="">
                      <div className="card wallet-card account-card mb-2 delivery-input-height">
                        <div className="card-body">
                          <p className="m-0">Ship to Address</p>
                          {/* <h4 className="">
                            {" "}
                          {address2}
                          </h4> */}
                          <textarea
                            rows={5}
                            className="form-control  form-details-textarea new-text-area-address "
                            id="floatingInputValue"
                            placeholder=" 124 City Road
                                Manchester
                                West Yorkshire
                                SW1V 2NX"
                            value={address1 ? address1 : "-"}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div>
                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">Status</p>
                          <h4 className="">Pending </h4>
                        </div>
                      </div>
                      <div className="card wallet-card account-card mb-2">
                        <div className="card-body">
                          <p className="m-0">Tracking Number</p>
                          <h4 className=""> {salesData?.shippingLabel?.tracking_number || "-"}</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>


        <ChatComponent salesData={salesData} />


        {salesData?.itemIssue?.user_issue == 2 && (
          <Row className="mb-4 ">
            <Col lg={12}>
              <div className="card purchase-card">
                <div className="card-body profile-crd listing-details-card buyers-card">
                  <h2 className="mb-2">Disputed </h2>
                  {/* <div className="no-content ">
                  <p>No details to show yet</p>
                </div> */}
                  <Row>
                    <Col lg={8}>
                      <div>
                        <Row>
                          <Col lg={5}>
                            <div className="card wallet-card account-card mb-2">
                              <div className="card-body">
                                <p className="m-0">Type</p>
                                <h4 className="">{issueMessages[salesData?.itemIssue?.sub_issue]}</h4>
                              </div>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="card wallet-card account-card mb-2 delivery-input-height">
                              <div className="card-body">
                                <p className="m-0">Details</p>
                                <h4 className="">
                                  {salesData?.itemIssue?.image_description}
                                </h4>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col lg={4}>
                      <div>
                        <div className="card wallet-card account-card mb-2 select-wi-label">
                          <div className="card-body">
                            <p className="m-0">Status</p>
                            <select
                              id="category"
                              className=""
                              value={status !== "" ? status : salesData?.itemIssue?.is_resolved}
                              onChange={handleStatusChange}
                            >
                              <option className="" value="">
                                Select
                              </option>
                              <option className="" value="0">
                                Unresolved
                              </option>
                              <option className="" value="1">
                                Resolved - Buyer Refunded
                              </option>
                              <option className="" value="2">
                                Resolved - No Refund
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="card wallet-card ">
                          <div className=" card-body ">
                            <p className="m-0">Images</p>

                            <div className="  account-card select-wi-label item-images img-gellar-set">
                              {salesData?.itemIssue?.image_url?.length > 0 && salesData?.itemIssue?.image_url?.map((elem, i) => (
                                <img key={i} src={ `${process.env.REACT_APP_IMG_URL}${elem} `}
                                  alt=""
                                  className="list-image"
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
}
