export function formatDateToDMY(dateString, type) {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  if (type) {
    return `${year}-${month}-${day}`;
  } else {
    return `${day}/${month}/${year}`;
  }
}

export function getFormattedTime(dateString) {
  const date = new Date(dateString);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter?.format(date);
}

export function formatDate(inputDate) {
  // Convert the input to a Date object if it's not already
  const date = new Date(inputDate);

  // Check if date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthsOfYear[date.getMonth()];

  // Get the appropriate suffix for the day of the month (st, nd, rd, th)
  const suffix =
    dayOfMonth % 10 === 1 && dayOfMonth !== 11
      ? "st"
      : dayOfMonth % 10 === 2 && dayOfMonth !== 12
        ? "nd"
        : dayOfMonth % 10 === 3 && dayOfMonth !== 13
          ? "rd"
          : "th";

  // Check if the provided date is today
  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  return `${dayOfWeek}, ${dayOfMonth}${suffix} ${month}${isToday ? " (Today)" : ""
    }`;
}

export function genderfunc(val) {
  if (val == 0) {
    return "Male";
  } else if (val == 1) {
    return "Female";
  } else if (val == 2) {
    return "Unisex";
  } else if (val == 3) {
    return "Junior"
  } else {
    return "-";
  }
}


export const capitalize = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return '-';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};
