import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import { userDetailById, userDetailForSold } from "../Redux/Actions/auth";
import CommanUserNav from "./CommanUserNav";
import NoData from "../components/NoData";
import { formatDateToDMY } from "../Utils/commonFiles";
import logo from "../assets/images/place-holder.png";
import Pagination from "../components/Pagination";

export default function UserSold() {
  const { id, name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const Image_url = process.env.REACT_APP_IMG_URL + "/uploads/";
  const userDataSold = useSelector((e) => e.userAuth.userSoldDetail);
  const userData = useSelector((e) => e.userAuth.userDetail);

  // useEffect(() => {
  //   dispatch(startstopLoading(true));
  //   dispatch(userDetailById({ id: id }))
  //   dispatch(userDetailForSold({ id: id,  page: 1, limit: 10 })).then((res) => {
  //     if (res?.payload?.success == true || res?.payload?.success == false) {
  //       dispatch(startstopLoading(false));
  //     }
  //   });
  // }, [dispatch]);

  //   const handlePageClick = (selected) => {
  //     dispatch(
  //       userDetailForSold({ page: selected.selected + 1 , limit:10, id: id})
  //     );
  //   };

    const [searchQuery, setSearchQuery] = useState(""); 
  
    useEffect(() => {
      dispatch(startstopLoading(true));
      dispatch(userDetailById({ id: id }));
      fetchUserList();
    }, [dispatch]);
  
    const fetchUserList = (searchTerm = "") => {
      dispatch(
        userDetailForSold({ id: id, page: 1, limit: 10, searchTerm })
      ).then((res) => {
        if (res?.payload?.success === true || res?.payload?.success === false) {
          dispatch(startstopLoading(false));
        }
      });
    };
  
    const handleSearch = (e) => {
      const value = e.target.value;
      setSearchQuery(value);
      fetchUserList(value);
    };
  
    const handlePageClick = (selected) => {
      dispatch(
        userDetailForSold({
          page: selected.selected + 1,
          limit: 10,
          id: id,
          searchTerm: searchQuery, 
        })
      );
    };
  

  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span>All Users/ @{name}</span>
                <div className="user-name-contain">
                  <div className="left-arrow-icon">
                    <Link onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }}>
                      <i>
                        <svg
                          fill="rgba(204, 210, 227, 1)"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="20px"
                          height="20px"
                          viewBox="0 0 100 100"
                          enable-background="new 0 0 100 100"
                          // xml:space="preserve"
                          stroke="rgba(204, 210, 227, 1)"
                          stroke-width="2.5"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <path d="M33.934,54.458l30.822,27.938c0.383,0.348,0.864,0.519,1.344,0.519c0.545,0,1.087-0.222,1.482-0.657 c0.741-0.818,0.68-2.083-0.139-2.824L37.801,52.564L64.67,22.921c0.742-0.818,0.68-2.083-0.139-2.824 c-0.817-0.742-2.082-0.679-2.824,0.139L33.768,51.059c-0.439,0.485-0.59,1.126-0.475,1.723 C33.234,53.39,33.446,54.017,33.934,54.458z"></path>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </i>
                    </Link>
                  </div>
                  <div className="user-name">
                    <h4>@{name}</h4>
                    {/* <span>00001</span> */}
                  </div>
                </div>
              </div>
              <div className="title-pro-right">
                <CommanUserNav id={userData?.id} />

                <div className="">
                  <Link to="#" className="waves-effect">
                    <i className="">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_2242_20999)">
                          <path d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z" fill="#C0C0C0" />
                        </g>
                        <defs>
                          <clipPath id="clip0_2242_20999">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mb-4 search-bar">
          <div className="">
            <div className="">
              <div>
                <input
                  type="search"
                  placeholder="Search"
                  className="input-common-properties  "
                  value={searchQuery}
                  onChange={handleSearch} 
                />
              </div>
            </div>
          </div>
        </div>
        <Row>
          <Col lg={12}>
            <div class="card min-vh-80">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                  <h4 class="title_text">Sold ({userDataSold?.totalItems})</h4>
                </div>

                <div class="table-responsive">
                  <table
                    id=""
                    class="table dt-responsive commom-tables nowrap w-100"
                  >
                    <thead>
                      <tr>
                        <th className="id-field">S.No</th>
                        <th className="id-field">Listed</th>
                        <th className="id-field">Image</th>
                        <th className="id-field">Item Name</th>
                        <th className="id-field">Category </th>
                        <th className="id-field">Sub Categaory</th>
                        <th className="id-field">List Price</th>
                        <th className="id-field">Offers</th>
                        <th className="id-field">Status</th>
                        <th className="id-field">Sale Price </th>

                        <th>Buyer</th>
                      </tr>
                    </thead>
                    <tbody class="td_color">
                      {userDataSold?.itemsSold?.length > 0 ? userDataSold?.itemsSold?.map((item, i) => (
                        <tr key={i}>
                              <td>{(userDataSold?.currentPage - 1) * 10 + i + 1}</td>
                          <td>{formatDateToDMY(item?.createdAt)}</td>
                          <td>
                            {item?.item?.item_Image?.map((elem, i) => (
                              <img
                                key={i}
                                src={
                                  elem
                                    ? elem.startsWith("uploads/")
                                      ? process.env.REACT_APP_IMG_URL +
                                      "/" +
                                      elem
                                      : Image_url + elem
                                    : logo
                                }
                                alt=""
                                className="list-image"
                              />
                            ))}
                          </td>
                          <td>{item?.item?.item_Title}</td>
                          <td>{item?.item?.category?.category_name}</td>
                          <td>{item?.item?.sub_Category?.sub_category_name}</td>
                          <td>£{item?.item?.price?.toFixed(2)}</td>
                          <td>{item?.total_offers}</td>
                          <td>Sold Completed</td>
                          <td>£{item?.amount ? item?.amount?.toFixed(2) : '-'}</td>
                          <td>@{item?.buyer_name}</td>
                        </tr>
                      )) : <NoData />}
                    </tbody>
                  </table>

                  {userDataSold?.totalPages > 1 && (
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageCounttotal={
                        Math.ceil(userDataSold?.totalPages) || 1
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
