import { createSlice } from "@reduxjs/toolkit";
import { getUserList, listSubAdminOrUser, userDetailById, userDetailForPurchase, userDetailForReviews, userDetailForSold, userDetailForTransaction, userListingById } from "../Actions/auth";

const initialState = {
  loading: false,
  userList: [],
  userDetail: [],
  listOfAdminOrSubAdmin: [],
  userListingDetail: [],
  userSoldDetail: [],
  userPurchaseDetail: [],
  userTransactionDetail: [],
  currentPage: 1,
  userReviewsDetail: []
}

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    resetPage: (state) => {
      state.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      if (payload?.success) {
        state.success = true;
        state.userList = payload.data;
      } else if (!payload.success) {
      }
    })

      // ==============================================================================

      .addCase(userDetailById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.userDetail = payload.data;
        } else if (!payload.success) {
        }
      })

      //   ==========================================================================

      .addCase(listSubAdminOrUser.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.listOfAdminOrSubAdmin = payload.data;
        } else if (!payload.success) {
        }
      })

      // =============================================================================

      .addCase(userListingById.fulfilled, (state, { payload }) => {
        // if (payload?.success) {
          state.success = true;
          state.userListingDetail = payload.data;
        // } else if (!payload.success) {
        // }
      })

      // =============================================================================

      .addCase(userDetailForSold.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.userSoldDetail = payload.data;
        } else if (!payload.success) {
        }
      })

      // =============================================================================

      .addCase(userDetailForPurchase.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.userPurchaseDetail = payload.data;
        } else if (!payload.success) {
        }
      })


      // =============================================================================

      .addCase(userDetailForTransaction.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.userTransactionDetail = payload.data;
        } else if (!payload.success) {
        }
      })

      // =============================================================================

      .addCase(userDetailForReviews.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.userReviewsDetail = payload.data;
        } else if (!payload.success) {
        }
      })

  },
});

export const { setCurrentPage, resetPage } = UserSlice.actions;
export default UserSlice.reducer;