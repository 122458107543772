import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateUserByAdmin, userDetailById } from "../Redux/Actions/auth";
import { formatDateToDMY } from "../Utils/commonFiles";
import ConfirmModal from "../ modals/ConfirmModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import profile from "../assets/images/place-holder.png";
import { startstopLoading } from "../Redux/Reducers/globalSlice";
import CommanUserNav from "./CommanUserNav";
import logo from "../assets/images/place-holder.png";

// Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required("First name is required"),
  user_name: Yup.string().required("user_name is required"),
  // dob: Yup.date().required('DOB is required'),
  title: Yup.string().max(200, "Bio should not exceed 200 characters"),
});

const initialData = {
  image: "",
  name: "",
  user_name: "",
  dob: "",
  title: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  country: "",
  post_Code: "",
  marketing: "",
  holidayMode: "",
};

export default function UserOverview() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const Image_url = process.env.REACT_APP_IMG_URL + "/uploads/";

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  let permissionAccess = JSON.parse(sessionStorage.getItem("permission"));

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);
      setIsEdit(!isEdit);
      const imageUrl = URL.createObjectURL(file);
      setImagePreview(imageUrl);
    }
  };

  const { id, name } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((e) => e.userAuth.userDetail);

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(userDetailById({ id: id, type: "overview" })).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
  }, [dispatch, isEdit, isModalOpen]);

  const handleUserDelete = (type) => {
    setModalOpen(true);
    setSelectedType(type);
  };

  const handleSuspendUser = (type) => {
    setModalOpen(true);
    setSelectedType(type);
  };

  const handleResetUser = (type) => {
    setModalOpen(true);
    setSelectedType(type);
  };

  const handleConfirmClose = async () => {
    setModalOpen(false);
  };

  const handleEdit = async () => {
    setIsEdit(!isEdit);
  };

  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span>All Users/ @{userData?.user_name}</span>
                <div className="user-name-contain">
                  <div className="left-arrow-icon">
                    <Link onClick={(e) => {
                      e.preventDefault();
                      navigate(-1);
                    }} >
                      <i className="d-flex align-items-center justify-content-center">
                        <svg
                          fill="rgba(204, 210, 227, 1)"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="20px"
                          height="20px"
                          viewBox="0 0 100 100"
                          enable-background="new 0 0 100 100"
                          // xml:space="preserve"
                          stroke="rgba(204, 210, 227, 1)"
                          stroke-width="2.5"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <path d="M33.934,54.458l30.822,27.938c0.383,0.348,0.864,0.519,1.344,0.519c0.545,0,1.087-0.222,1.482-0.657 c0.741-0.818,0.68-2.083-0.139-2.824L37.801,52.564L64.67,22.921c0.742-0.818,0.68-2.083-0.139-2.824 c-0.817-0.742-2.082-0.679-2.824,0.139L33.768,51.059c-0.439,0.485-0.59,1.126-0.475,1.723 C33.234,53.39,33.446,54.017,33.934,54.458z"></path>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </i>
                    </Link>
                  </div>
                  <div className="user-name">
                    <h4 >@{name}</h4>
                    {/* <span>00001</span> */}
                  </div>
                </div>
              </div>
              <div className="title-pro-right">
                <CommanUserNav id={userData?.id} />

                <div className="">
                  <Link to="#" className="waves-effect">
                    <i className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_2242_20999)">
                          <path
                            d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z"
                            fill="#C0C0C0"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2242_20999">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col lg={4}>
            <div className="card mb-4 mb-lg-0">
              <div className="card-body total-car profile-card">
                <div className="user-pro-upld">
                  <img
                    // src={ imagePreview ? imagePreview: userData?.image ? Image_url + userData?.image : profile }
                    src={
                      imagePreview
                        ? imagePreview
                        : userData?.image
                          ? userData?.image.startsWith("uploads/")
                            ? process.env.REACT_APP_IMG_URL +
                            "/" +
                            userData?.image
                            : Image_url + userData?.image
                          : profile
                    }
                    alt=""
                    className="list-image"
                  />

                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="useroverview-h3-p">
                  <h3>{userData?.name}</h3>
                  <p>@{userData?.user_name}</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <div className="card">
              <div className="card-body ">
                <div className="">
                  <h4 className="wallet-heading">Wallet</h4>
                  <div className="wallet-tabs">
                    <div className="card wallet-card ">
                      <div className="card-body ">
                        <p>Available Balance</p>
                        {/* <h3> £{userData?.available_balance?.toFixed(2)}</h3> */}
                        <h3>£{(userData?.available_balance || 0).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>

                      </div>
                    </div>

                    <div className="card wallet-card">
                      <div className="card-body">
                        <p>Pending</p>
                        <h3> £{userData?.pending_balance?.toFixed(2)}</h3>
                      </div>
                    </div>

                    <div className="card wallet-card">
                      <div className="card-body">
                        <p>Total Earnings</p>
                        <h3> £{userData?.total_earning?.toFixed(2)}</h3>
                      </div>
                    </div>

                    <div className="card wallet-card">
                      <div className="card-body">
                        <p>Total Withdrawn</p>
                        <h3> £{userData?.total_withdrawn?.toFixed(2)}</h3>
                      </div>
                    </div>

                    <div className="card wallet-card">
                      <div className="card-body">
                        <p>Total Spent</p>
                        <h3> £{userData?.total_spent?.toFixed(2)}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg={4}>
            <div className="card details-card-height mb-4">
              <div className="card-body details-card ">
                <div className="details-header">
                  <h4 className="wallet-heading m-0">Details</h4>
                  {permissionAccess ? (
                    <div onClick={handleEdit} style={{ cursor: "pointer" }}>
                      <i>
                        <svg
                          height="15px"
                          width="15px"
                          version="1.1"
                          id="_x32_"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 512 512"
                          // xml:space="preserve"
                          fill="rgba(143, 155, 186, 1)"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <style type="text/css">
                              {/* .st0 fill:rgba(143, 155, 186, 1); */}
                            </style>
                            <g>
                              <path
                                class="st0"
                                d="M494.56,55.774l-38.344-38.328c-23.253-23.262-60.965-23.253-84.226,0l-35.878,35.878l122.563,122.563 l35.886-35.878C517.814,116.747,517.814,79.044,494.56,55.774z"
                              ></path>
                              <polygon
                                class="st0"
                                points="0,389.435 0,511.998 122.571,511.998 425.246,209.314 302.691,86.751 "
                              ></polygon>
                            </g>
                          </g>
                        </svg>
                      </i>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <Formik
                  initialValues={userData || initialData}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    if (values) {
                      try {
                        const value = {
                          name: values.name,
                          user_name: values.user_name,
                          title: values.title,
                          user_id: values.id,
                          dob: "",
                          address1: values.address1,
                          address2: values.address2,
                          city: values.city,
                          country: values.country,
                          post_Code: values.post_Code,
                        };
                        const formData = new FormData();
                        if (selectedImage) {
                          formData.append("image", selectedImage);
                        }
                        for (const key in value) {
                          formData.append(key, value[key]);
                        }
                        const res = await dispatch(updateUserByAdmin(formData));
                        if (res?.payload?.status == 200) {
                          toast.success(res?.payload?.message);
                          setIsEdit(!isEdit);
                        } else {
                          toast.error(res?.payload?.message);
                        }
                      } catch (error) {
                        console.log("error", error);
                      }
                    }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className="form-details py-2">
                      {/* <div className="d-flex gap-2 mb-2">
                        <div className="form-floating input-width">
                          <Field
                            type="text"
                            name="name"
                            className="form-control form-details-input"
                            readOnly={isEdit ? false : true}
                          />
                          <label htmlFor="name" className="form-details-label">First name</label>
                          {isEdit && <ErrorMessage name="name" component="div" className="error" />}
                        </div>
                      </div> */}

                      <div className="d-flex gap-2 mb-2">
                        <div className="form-floating input-width">
                          <Field
                            type="text"
                            name="name"
                            className="form-control form-details-input"
                            readOnly={isEdit ? false : true}
                          />
                          <label htmlFor="name" className="form-details-label">
                            Name
                          </label>
                          {isEdit && (
                            <ErrorMessage
                              name="name"
                              component="div"
                              style={{ color: "red" }}
                              className="error"
                            />
                          )}
                        </div>

                        <div className="form-floating input-width">
                          <Field
                            type="text"
                            name="user_name"
                            className="form-control form-details-input"
                            readOnly={isEdit ? false : true}
                          />
                          <label
                            htmlFor="username"
                            className="form-details-label"
                          >
                            Username
                          </label>
                          {isEdit && (
                            <ErrorMessage
                              name="user_name"
                              component="div"
                              style={{ color: "red" }}
                              className="error"
                            />
                          )}
                        </div>
                        {/* <div className="form-floating input-width">
                          <Field
                            type="date"
                            name="dob"
                            className="form-control form-details-input"
                          
                            readOnly={isEdit ? false : true}

                          />
                          <label htmlFor="dob" className="form-details-label dob-label">DOB</label>
                          {isEdit && <ErrorMessage name="dob" component="div" className="error" />}
                        </div> */}
                      </div>

                      <div className="my-4 textarea-bio">
                        <div className="form-floating">
                          <Field
                            as="textarea"
                            rows={5}
                            name="title"
                            className="form-control form-details-input form-details-textarea w-100"
                            readOnly={isEdit ? false : true}
                          // validate={(value) => {
                          //   let error;
                          //   if (value && value.length > 200) {
                          //     error = 'Bio should not exceed 200 characters';
                          //   }
                          //   return error;
                          // }}
                          />
                          <label htmlFor="bio" className="form-details-label">
                            Bio
                          </label>
                          {isEdit && (
                            <ErrorMessage
                              name="title"
                              component="div"
                              style={{ color: "red" }}
                              className="error"
                            />
                          )}
                        </div>
                      </div>

                      <div className="my-4">
                        <div className="form-floating">
                          <Field
                            type="email"
                            name="email"
                            className="form-control form-details-input"
                            placeholder="ash@yopmail.com"
                            readOnly
                          />
                          <label htmlFor="email" className="form-details-label">
                            Email
                          </label>
                          {isEdit && (
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error"
                            />
                          )}
                        </div>
                      </div>

                      <div className="address-textarea mb-2">
                        {/* <div className="form-floating input-width">
                          <Field
                            as="textarea"
                            rows={4}
                            name="address"
                            className="form-control form-details-input form-details-textarea"
                            placeholder=""
                            readOnly={isEdit ? false : true}
                          />
                          <label htmlFor="address" className="form-details-label">Address</label>
                          {isEdit && <ErrorMessage name="address" component="div" className="error" />}
                        </div> */}

                        {/* Address 1 */}
                        <div className="form-floating ">
                          <Field
                            as="textarea"
                            rows={5}
                            name="address1"
                            className="form-control form-details-input form-details-textarea w-100"
                            readOnly={isEdit ? false : true}
                          />
                          {/* <Field
                            type="text"
                            name="address1"
                            className="form-control form-details-input"
                            placeholder=""
                            readOnly={isEdit ? false : true}
                          /> */}
                          <label
                            htmlFor="address1"
                            className="form-details-label"
                          >
                            Address 1
                          </label>
                          {isEdit && (
                            <ErrorMessage
                              name="address1"
                              component="div"
                              className="error"
                            />
                          )}
                        </div>

                        {/* Address 2 */}
                        <div className="form-floating ">
                          <Field
                            as="textarea"
                            rows={5}
                            name="address2"
                            className="form-control form-details-input form-details-textarea w-100"
                            readOnly={isEdit ? false : true}
                          />
                          {/* <Field
                            type="text"
                            name="address2"
                            className="form-control form-details-input"
                            placeholder=""
                            readOnly={isEdit ? false : true}
                          /> */}
                          <label
                            htmlFor="address2"
                            className="form-details-label"
                          >
                            Address 2
                          </label>
                          {isEdit && (
                            <ErrorMessage
                              name="address2"
                              component="div"
                              className="error"
                            />
                          )}
                        </div>

                        {/* City */}
                        <div className="d-flex gap-2 mb-2">
                          <div className="form-floating input-width">
                            <Field
                              type="text"
                              name="city"
                              className="form-control  form-details-input w-100"
                              placeholder=""
                              readOnly={isEdit ? false : true}
                            />
                            <label
                              htmlFor="city"
                              className="form-details-label "
                            >
                              City
                            </label>
                            {isEdit && (
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="error"
                              />
                            )}
                          </div>

                          <div className="col-md-6">
                            {/* Country */}
                            <div className="form-floating ">
                              <Field
                                type="text"
                                name="country"
                                className="form-control form-details-input w-100"
                                placeholder=""
                                readOnly={isEdit ? false : true}
                              />
                              <label
                                htmlFor="country"
                                className="form-details-label"
                              >
                                Country
                              </label>
                              {isEdit && (
                                <ErrorMessage
                                  name="country"
                                  component="div"
                                  className="error"
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="">
                          <div className="form-floating">
                            <Field
                              type="text"
                              name="post_Code"
                              className="form-control form-details-input"
                              placeholder=""
                              readOnly={isEdit ? false : true}
                            />
                            <label
                              htmlFor="post_Code"
                              className="form-details-label"
                            >
                              Post Code
                            </label>
                            {isEdit && (
                              <ErrorMessage
                                name="post_Code"
                                component="div"
                                className="error"
                              />
                            )}
                          </div>
                        </div>

                        <div className="d-flex gap-2 mb-2">
                          <div className="form-floating input-width">
                            {" "}
                            <Field
                              type="text"
                              name="marketing"
                              className="form-control form-details-input"
                              readOnly
                              value={userData?.subscribe_newsletter == "0" ? "No" : "Yes"}
                            />
                            <label
                              htmlFor="marketing"
                              className="form-details-label"
                            >
                              Marketing
                            </label>
                            {isEdit && (
                              <ErrorMessage
                                name="marketing"
                                component="div"
                                className="error"
                              />
                            )}
                          </div>

                          <div className="col-md-6">
                            <div className="form-floating ">
                              <Field
                                type="text"
                                name="holidayMode"
                                className="form-control form-details-input"
                                readOnly
                                value={userData?.holiday_mode == "0" ? "No" : "Yes"}
                              />
                              <label
                                htmlFor="holidayMode"
                                className="form-details-label"
                              >
                                Holiday Mode
                              </label>
                              {isEdit && (
                                <ErrorMessage
                                  name="holidayMode"
                                  component="div"
                                  className="error"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {isEdit && (
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <Row className="mb-4">
              <Col>
                <div className="card">
                  <div className="card-body details-card">
                    <div className="mb-2">
                      <h4 className="wallet-heading m-0">Account Statistics</h4>
                    </div>
                    <Row>
                      <Col lg={4}>
                        <div>
                          <div className="card wallet-card account-card mb-2">
                            <div className="card-body">
                              <p className="m-0">Registration Date</p>
                              <h4 className="">
                                {formatDateToDMY(userData?.createdAt)}
                              </h4>
                            </div>
                          </div>
                          <div className="card wallet-card account-card mb-2">
                            <div className="card-body">
                              <p className="m-0">Verified Status</p>
                              {userData?.is_otp_verified == 1 ? (
                                <h4 className=""> Email Verified</h4>
                              ) : (
                                <h4 className=""> Not Verified</h4>
                              )}
                            </div>
                          </div>
                          <div className="card wallet-card account-card mb-2">
                            <div className="card-body">
                              <p className="m-0">Account Status</p>
                              {userData?.issuspended == 0 ? (
                                <h4 className=""> Active</h4>
                              ) : (
                                <h4 className="">Suspended</h4>
                              )}
                            </div>
                          </div>
                          <div className="card wallet-card account-card mb-2">
                            <div className="card-body">
                              <p className="m-0">Last Active</p>
                              <h4 className="">
                                {formatDateToDMY(userData?.updatedAt)}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="">
                          <div className="card wallet-card account-card mb-2">
                            <div className="card-body">
                              <p className="m-0">Purchases</p>
                              <h4 className="">{userData?.totalboughtitems}</h4>
                            </div>
                          </div>
                          <div className="card wallet-card account-card mb-2">
                            <div className="card-body">
                              <p className="m-0">Sold</p>
                              <h4 className="">{userData?.total_sold_items}</h4>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <div className="card wallet-card account-card mb-2">
                            <div className="card-body">
                              <p className="m-0">Max Spend</p>
                              <h3> £{userData?.highest_purchase ? userData?.highest_purchase?.amount?.toFixed(2) : "0.00"}</h3>
                            </div>
                          </div>
                          <div className="card wallet-card account-card mb-2">
                            <div className="card-body">
                              <p className="m-0">Lowest Spend</p>
                              <h3> £{userData?.lowest_purchase?.amount ? userData?.lowest_purchase?.amount?.toFixed(2) : "0.00"}</h3>
                            </div>
                          </div>
                          <div className="card wallet-card account-card mb-2">
                            <div className="card-body">
                              <p className="m-0">Average Spend</p>
                              <h3> £{userData?.average_spent ? userData?.average_spent?.toFixed(2) : "0.00"}</h3>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <div className="card account-card-height mb-4">
                  <div className="card-body details-card">
                    <div className="mb-3 account-actions ">
                      <h4 className="wallet-heading m-0 ">Account Actions</h4>
                    </div>
                    <div className="account-btns d-flex align-items-center flex-column">
                      <div className="mb-2">
                        {userData?.issuspended == 1 ? (
                          <button
                            className="suspend-btn"
                            onClick={() => handleSuspendUser("userActive")}
                          >
                            {/* Active Account */}
                            Re-Activate Account
                          </button>
                        ) : (
                          <button
                            className="suspend-btn"
                            onClick={() => handleSuspendUser("userSuspend")}
                          >
                            Suspend Account
                          </button>
                        )}
                      </div>
                      <div className="mb-2">
                        <button
                          className="suspend-btn"
                          onClick={() => handleResetUser("userReset")}
                        >
                          Reset Password
                        </button>
                      </div>
                      <div className="mb-2">
                        <button
                          className="suspend-btn"
                          onClick={() => handleUserDelete("userDelete")}
                        >
                          Delete Account
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div class="card ">
                  <div class="card-body recent-likes-card">
                    <div class="d-flex justify-content-between align-items-center flex-wrap position-relative">
                      <h4 class="title_text recent-likes mb-0">Recent Likes</h4>
                    </div>

                    <div class="table-responsive height-fixed-table">
                      <table id="" class="table recent-likes-table nowrap mb-0">
                        <thead>
                          <tr>
                            <th className="id-field">S.No</th>
                            <th className="id-field">Image</th>
                            <th className="id-field">Name</th>
                            <th className="id-field">Category </th>
                          </tr>
                        </thead>
                        <tbody class="td_color">

                          {userData?.favorite_items?.map((elem, i) => (
                            <tr>
                              <td>{i+1}</td>
                              <td>
                              {elem?.item_Image?.map((elem, i) => (
                                <img
                                  key={i}
                                  src={
                                    elem
                                      ? elem.startsWith("uploads/")
                                        ? process.env.REACT_APP_IMG_URL +
                                        "/" +
                                        elem
                                        : Image_url + elem
                                      : logo
                                  }
                                  alt=""
                                  className="list-image"
                                />
                              ))}
                              </td>
                              <td>{elem?.item_Title}</td>
                              <td>{elem?.category_name}</td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <ConfirmModal
          data={id}
          isModalOpen={isModalOpen}
          selectedType={selectedType}
          handleConfirmClose={handleConfirmClose}
        />
      </Container>
    </Layout>
  );
}
