import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../service/Api";

export const getTransactionData = createAsyncThunk("getTransactionData", async ({ page, limit, search, transtype }) => {
    const response = await API.get('transactionslisting', {
      params: { page, limit, search, transtype }
    });
    return response.data;
  });

