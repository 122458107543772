import { configureStore } from "@reduxjs/toolkit";
import  globalReducer from "../Redux/Reducers/globalSlice" 
import userReducer from "../Redux/Reducers/authSlice"
import itemReducer from "../Redux/Reducers/userList"
import salesReducer from "../Redux/Reducers/salesSlice"
import feedbackReducer from "../Redux/Reducers/feedbackSlice"
import mailingReducer from "../Redux/Reducers/mailingSlice"
import notificationReducer from "../Redux/Reducers/notificationSlice"
import transactionReducer from "../Redux/Reducers/transactionSlice"

export const store = configureStore({
  reducer: {
    globalSlice: globalReducer,
    userAuth: userReducer,
    itemAuth : itemReducer,
    salesAuth : salesReducer,
    feedbackAuth : feedbackReducer,
    mailingAuth : mailingReducer,
    notificationAuth : notificationReducer,
    transactionAuth: transactionReducer
  },
});
