import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserList } from "../Redux/Actions/auth";
import profile from "../assets/images/place-holder.png";
import { formatDateToDMY } from "../Utils/commonFiles";
import NoData from "../components/NoData";
import Pagination from "../components/Pagination";
import { setCurrentPage } from "../Redux/Reducers/authSlice";

export default function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const Image_url = process.env.REACT_APP_IMG_URL + "/uploads/";

   const [searchTerm, setSearchTerm] = useState('');
  //  const [currentPage, setCurrentPage] = useState(1);

   const userData = useSelector((e) => e.userAuth.userList);
   const currentPage = useSelector((state) => state.userAuth.currentPage);

  // useEffect(() => {
  //   dispatch(getUserList({searchTerm: searchTerm, page: currentPage, limit: 10 }));
  // }, [currentPage, searchTerm]);

  // const handlePageClick = (selected) => {
  //   const newPage = selected.selected + 1;
  //   setCurrentPage(newPage);
  // };

  // const handleSearchChange = (e) => {
  //   const term = e.target.value;
  //   setSearchTerm(term);
  //   setCurrentPage(1); 
  // };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const page = query.get('page') || 1;
    dispatch(setCurrentPage(parseInt(page, 10)))
  }, [location.search]);

  useEffect(() => {
    dispatch(getUserList({ searchTerm, page: currentPage, limit: 10 }));
  }, [currentPage, searchTerm]);

  const handlePageClick = (selected) => {
    const newPage = selected.selected + 1;
    // setCurrentPage(newPage);
    dispatch(setCurrentPage(newPage));
    navigate(`/users?page=${newPage}`);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    dispatch(setCurrentPage(1));
    navigate(`/users?page=1`);
  };

  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span>All Users</span>
                <h4>All Users </h4>
              </div>
              <div className="title-pro-right">
                <div className="">
                  <Link to="#" className="waves-effect">
                    <i className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_2242_18853)">
                          <path
                            d="M19.2905 17.29L18.0005 16V11C18.0005 7.93 16.3605 5.36 13.5005 4.68V4C13.5005 3.17 12.8305 2.5 12.0005 2.5C11.1705 2.5 10.5005 3.17 10.5005 4V4.68C7.63054 5.36 6.00054 7.92 6.00054 11V16L4.71054 17.29C4.08054 17.92 4.52054 19 5.41054 19H18.5805C19.4805 19 19.9205 17.92 19.2905 17.29ZM16.0005 17H8.00054V11C8.00054 8.52 9.51054 6.5 12.0005 6.5C14.4905 6.5 16.0005 8.52 16.0005 11V17ZM12.0005 22C13.1005 22 14.0005 21.1 14.0005 20H10.0005C10.0005 21.1 10.8905 22 12.0005 22Z"
                            fill="#C0C0C0"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2242_18853">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>  
                    </i>
                  </Link>
                  <img
                    className="align-self-end"
                    src={require("../assets/images/avatar.svg").default}
                    // src={require("../assets/images/place-holder.png").default}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col lg={2}>
            <div className="card">
              <div className="card-body total-card">
                <div className="bg-circle">
                  <i>
                    <svg
                      fill="rgba(67, 24, 255, 1)"
                      width="24px"
                      height="24px"
                      viewBox="0 0 32 32"
                      // style="fill-rule=evenodd; clip-rule:evenodd; stroke-linejoin:round; stroke-miterlimit:2;"
                      version="1.1"
                      // xml:space="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      // xmlns:serif="http://www.serif.com/"
                      //  xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M29,10c0,-0.552 -0.448,-1 -1,-1l-4,0c-0.552,0 -1,0.448 -1,1l-0,18c0,0.552 0.448,1 1,1l4,0c0.552,0 1,-0.448 1,-1l-0,-18Zm-20,6c-0,-0.552 -0.448,-1 -1,-1l-4,0c-0.552,0 -1,0.448 -1,1l-0,12c0,0.552 0.448,1 1,1l4,0c0.552,0 1,-0.448 1,-1l-0,-12Zm10,-12c0,-0.552 -0.448,-1 -1,-1l-4,0c-0.552,0 -1,0.448 -1,1l-0,24c0,0.552 0.448,1 1,1l4,0c0.552,0 1,-0.448 1,-1l-0,-24Z"></path>
                        <g id="Icon"></g>
                      </g>
                    </svg>
                  </i>
                </div>
                <div>
                  <p>Total</p>
                  <h3>{userData?.totalUsers ? userData?.totalUsers : "0"}</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={2}>
            <div className="card">
              <div className="card-body total-card">
                <div className="bg-circle">
                  <i>
                    <svg
                      fill="rgba(67, 24, 255, 1)"
                      width="24px"
                      height="24px"
                      viewBox="0 0 32 32"
                      // style="fill-rule=evenodd; clip-rule:evenodd; stroke-linejoin:round; stroke-miterlimit:2;"
                      version="1.1"
                      // xml:space="preserve"
                      xmlns="http://www.w3.org/2000/svg"
                      // xmlns:serif="http://www.serif.com/"
                      //  xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M29,10c0,-0.552 -0.448,-1 -1,-1l-4,0c-0.552,0 -1,0.448 -1,1l-0,18c0,0.552 0.448,1 1,1l4,0c0.552,0 1,-0.448 1,-1l-0,-18Zm-20,6c-0,-0.552 -0.448,-1 -1,-1l-4,0c-0.552,0 -1,0.448 -1,1l-0,12c0,0.552 0.448,1 1,1l4,0c0.552,0 1,-0.448 1,-1l-0,-12Zm10,-12c0,-0.552 -0.448,-1 -1,-1l-4,0c-0.552,0 -1,0.448 -1,1l-0,24c0,0.552 0.448,1 1,1l4,0c0.552,0 1,-0.448 1,-1l-0,-24Z"></path>
                        <g id="Icon"></g>
                      </g>
                    </svg>
                  </i>
                </div>
                <div>
                  <p>Active</p>
                  <h3>{userData?.activeUsers ? userData?.activeUsers : "0"}</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={2}>
            <div className="card">
              <div className="card-body total-card">
                <div className="bg-circle">
                  <i>
                    <svg
                      fill="rgba(67, 24, 255, 1)"
                      width="24px"
                      height="24px"
                      viewBox="0 0 32 32"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M29,10c0,-0.552 -0.448,-1 -1,-1l-4,0c-0.552,0 -1,0.448 -1,1l-0,18c0,0.552 0.448,1 1,1l4,0c0.552,0 1,-0.448 1,-1l-0,-18Zm-20,6c-0,-0.552 -0.448,-1 -1,-1l-4,0c-0.552,0 -1,0.448 -1,1l-0,12c0,0.552 0.448,1 1,1l4,0c0.552,0 1,-0.448 1,-1l-0,-12Zm10,-12c0,-0.552 -0.448,-1 -1,-1l-4,0c-0.552,0 -1,0.448 -1,1l-0,24c0,0.552 0.448,1 1,1l4,0c0.552,0 1,-0.448 1,-1l-0,-24Z"></path>
                        <g id="Icon"></g>
                      </g>
                    </svg>
                  </i>
                </div>
                <div>
                  <p>Inactive</p>
                  <h3>
                    {userData?.inactiveUsers ? userData?.inactiveUsers : "0"}
                  </h3>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="d-flex justify-content-end align-items-center h-100">
              <div>
                <input
                  type="search"
                  placeholder="Search"
                  className="input-common-properties"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div class="card min-vh-80">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                  <h4 class="title_text">All Users</h4>
                </div>

                <div class="table-responsive">
                  <table
                    id=""
                    class="table dt-responsive commom-tables nowrap w-100"
                  >
                    <thead>
                      <tr>
                        <th className="id-field">ID</th>
                        <th className="id-field">Username</th>
                        <th className="id-field">Image</th>
                        <th className="id-field">Joining Date </th>
                        <th>Status</th>
                        <th className="id-field">Last Active </th>
                        <th className="id-field">Listings </th>
                        <th className="id-field">Sales</th>
                        <th className="id-field">Earnings</th>
                        <th className="id-field">Action</th>
                      </tr>
                    </thead>
                    <tbody class="td_color">
                      {userData?.users?.length > 0 ? (
                        userData?.users?.map((item, i) => (
                          <tr key={i}>
                            <td>{(userData?.currentPage - 1) * 10 + i + 1}</td>
                            <td>
                              <Link
                                to={`/user-overview/${item?._id}/${item?.user_name}`}
                              >
                                {item?.user_name ? `@${item?.user_name}` : ""}
                              </Link>
                            </td>

                            <td>
                              {/* <img src={ item.image ? Image_url + item?.image : profile }
                                  alt=""
                                  className="list-image"
                                /> */}
                              <img
                                src={
                                  item?.image
                                    ? item?.image.startsWith("uploads/")
                                      ? process.env.REACT_APP_IMG_URL +
                                        "/" +
                                        item.image
                                      : Image_url + item.image
                                    : profile
                                }
                                alt=""
                                className="list-image"
                              />
                            </td>
                            <td>{formatDateToDMY(item?.createdAt)}</td>

                            <td>
                              {item?.issuspended == 0 ? (
                                <div className="d-flex gap-2 align-items-center">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clip-path="url(#clip0_3368_3948)">
                                      <path
                                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                                        fill="#05CD99"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_3368_3948">
                                        <rect
                                          width="24"
                                          height="24"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                  Active
                                </div>
                              ) : (
                                <div className="d-flex gap-2 align-items-center">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.87867 8.46447C9.48815 8.07394 8.85499 8.07394 8.46446 8.46446V8.46446C8.07394 8.85499 8.07394 9.48815 8.46447 9.87867L10.5858 12L8.46447 14.1213C8.07395 14.5118 8.07395 15.145 8.46447 15.5355V15.5355C8.85499 15.926 9.48815 15.926 9.87867 15.5355L12 13.4142L14.1213 15.5355C14.5118 15.926 15.145 15.926 15.5355 15.5355V15.5355C15.926 15.145 15.926 14.5118 15.5355 14.1213L13.4142 12L15.5355 9.87867C15.926 9.48815 15.926 8.85499 15.5355 8.46447V8.46447C15.145 8.07395 14.5118 8.07395 14.1213 8.46447L12 10.5858Z"
                                      fill="#ED673C"
                                    />
                                  </svg>
                                  Inactive
                                </div>
                              )}
                            </td>
                            <td>{formatDateToDMY(item?.updatedAt)}</td>
                            <td>{item?.total_listing}</td>
                            <td>{item?.total_items_sold}</td>
                            <td>
                              £{" "}
                              {item?.total_earning !== undefined
                                ? item.total_earning.toLocaleString("en-GB", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : "0.00"}
                            </td>

                            <td>
                              <Link  to={`/user-overview/${item?._id}/${item?.user_name}`}>
                                View
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoData />
                      )}
                    </tbody>
                  </table>
                  {userData?.totalPages > 1 && (
                    <Pagination
                      handlePageClick={handlePageClick}
                      pageCounttotal={Math.ceil(userData?.totalPages) || 1}
                      initialPage={currentPage - 1}
                    />
                  )}
                </div>
              </div>
            </div>
          </Col>{" "}
        </Row>
      </Container>
    </Layout>
  );
}
